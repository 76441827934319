const TTS = {
  Get: async (token: string, text: string, languageCode: string) => {
    const resp = await fetch(`${import.meta.env.VITE_HOST}/tts`, {
      method: 'POST',
      body: JSON.stringify({
        text,
        languageCode,
      }),
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    });

    const data = await resp.blob();
    return data;
  },
  GetCard: async (token: string, cardId: string) => {
    const resp = await fetch(`${import.meta.env.VITE_HOST}/tts/${cardId}`, {
      method: 'GET',
      headers: {
        Authorization: token,
      },
    });

    const data = await resp.blob();
    return data;
  },
};

export default TTS;
