import { IRegisterResponse } from '../pages/Register/models';
import { IError, IFeedbackData, ILoginData, IRegisterData, IUpdateBody, IUpdatePasswordBody } from '../pages/Settings/models';
import { IUser } from '../state/auth';
import MockAPI, { IsMock } from '../utils/mock';

const Auth = {
  GetMe: async (token: string) => {
    try {
      const resp = await fetch(`${import.meta.env.VITE_HOST}/auth`, {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      });
      const user: IUser = await resp.json();
      localStorage.removeItem('mock');
      return user;
    } catch (err) {
      // We do this because on mock sometimes, the dashboard will then GetMe() as a real user, thus breaking the whole flow
      return MockAPI.GetMe();
    }
  },
  Login: async (data: ILoginData) => {
    if (IsMock()) {
      return MockAPI.GetMe();
    }

    const resp = await fetch(`${import.meta.env.VITE_HOST}/auth/login`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (resp.status === 200) {
      const respData: IUser = await resp.json();
      return respData;
    } else {
      if (resp.status === 400) {
        const errorData: IError = await resp.json();
        throw errorData;
      } else {
        throw new Error();
      }
    }
  },
  Register: async (data: IRegisterData) => {
    if (IsMock()) {
      return MockAPI.GetMe();
    }

    const resp = await fetch(`${import.meta.env.VITE_HOST}/auth/register`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (resp.status === 201) {
      const respData: IRegisterResponse = await resp.json();
      return respData;
    } else {
      if (resp.status === 400) {
        const errorData: IError = await resp.json();
        throw errorData;
      } else {
        throw new Error();
      }
    }
  },
  Feedback: async (data: IFeedbackData, token?: string) => {
    const resp = await fetch(`${import.meta.env.VITE_HOST}/auth/feedback`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token || '',
      },
    });
    if (resp.status === 204) {
      return;
    } else {
      if (resp.status === 400) {
        const errorData: IError = await resp.json();
        throw errorData;
      } else {
        throw new Error();
      }
    }
  },
  Update: async (data: IUpdateBody, token: string) => {
    if (IsMock()) {
      return MockAPI.Update(data);
    }

    const resp = await fetch(`${import.meta.env.VITE_HOST}/auth`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    if (resp.status !== 204) {
      if (resp.status === 400) {
        const errorData: IError = await resp.json();
        throw errorData;
      } else {
        throw new Error();
      }
    }
  },
  UpdatePassword: async (data: IUpdatePasswordBody, token: string) => {
    if (IsMock()) {
      return MockAPI.UpdatePassword(data);
    }

    const resp = await fetch(`${import.meta.env.VITE_HOST}/auth/password`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    if (resp.status !== 204) {
      if (resp.status === 400) {
        const errorData: IError = await resp.json();
        throw errorData;
      } else {
        throw new Error();
      }
    }
  },
};

export default Auth;
