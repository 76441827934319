import * as React from 'react';
import styled from 'styled-components';

const KeyboardShortcutsContainer = styled.div`
  position: absolute;
  top: 100px;
  right: 100px;
  color: ${(props) => props.theme.colors.hint};
  pointer-events: none;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    display: none;
  }
`;

const KeyboardShortcutDesc = styled.div`
  width: 200px;
`;

const KeyboardShortcut = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: nowrap;
`;

interface IKeyboardShortcutsProps {}

const KeyboardShortcuts: React.FC<IKeyboardShortcutsProps> = (props) => {
  return (
    <KeyboardShortcutsContainer>
      <KeyboardShortcut>
        <KeyboardShortcutDesc>F1</KeyboardShortcutDesc>
        <div>Show/Hide Shortcuts</div>
      </KeyboardShortcut>
      <br />
      <KeyboardShortcut>
        <KeyboardShortcutDesc>Space, Enter, F, Up</KeyboardShortcutDesc>
        <div>Flip Card</div>
      </KeyboardShortcut>
      <br />
      <KeyboardShortcut>
        <KeyboardShortcutDesc>1</KeyboardShortcutDesc>
        <div>Couldn&apos;t Remember</div>
      </KeyboardShortcut>
      <KeyboardShortcut>
        <KeyboardShortcutDesc>2, Space, Enter</KeyboardShortcutDesc>
        <div>Remembered</div>
      </KeyboardShortcut>
      <br />
      <KeyboardShortcut>
        <KeyboardShortcutDesc>F11</KeyboardShortcutDesc>
        <div>Toggle Fullscreen</div>
      </KeyboardShortcut>
    </KeyboardShortcutsContainer>
  );
};

export default KeyboardShortcuts;
