import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-right: 10px;
  font-size: 1.2em;
`;

interface IButtonIconProps {
  icon: IconProp;
}

const ButtonIcon: React.FC<IButtonIconProps> = (props) => {
  return (
    <Container>
      <FontAwesomeIcon icon={props.icon} />
    </Container>
  );
};

export default ButtonIcon;
