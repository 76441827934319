import * as React from 'react';
import { PropsWithChildren } from 'react';
import useAuthState from '../state/auth';

// Error tracking lib.

export const ErrorBoundary: React.FC<PropsWithChildren<{}>> = (props) => {
  const auth = useAuthState();
  React.useEffect(() => {
    if (auth.User) {
      // ... set user here ...
    }
  }, [auth.User]);

  return <>{props.children}</>;
};

export const Notify = (error: any) => {
  // Bugsnag.notify(error);
  console.error(error);
};
