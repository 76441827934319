import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Decks from '../../api/decks';
import Languages from '../../api/languages';
import Stateless from './index.stateless';
import useAuthState from '../../state/auth';
import useDecksState from '../../state/decks';
import useLanguagesState, { ILanguage } from '../../state/languages';
import { INewDeckFormData } from './models';

const NewDeck: React.FC = () => {
  const authState = useAuthState();
  const decksState = useDecksState();
  const languagesState = useLanguagesState();
  const navigate = useNavigate();

  React.useEffect(() => {
    Languages.Get().then((langs: ILanguage[]) => {
      languagesState.SetLanguages(langs);
    });
  }, []);

  const onSubmit = async (data: INewDeckFormData) => {
    plausible('Decks/Create', {
      props: { language: languagesState.Languages.find((v) => v.id === data.baseLanguageId)?.name || data.baseLanguageId },
    });

    const deckData = await Decks.CreateDeck(
      authState.User!.token,
      data,
      languagesState.Languages.find((p) => p.id === data.baseLanguageId)!
    );

    decksState.AddDeck(deckData);
    navigate(`/dashboard/decks/${deckData.id}`);
  };

  return <Stateless languages={languagesState.Languages} onSubmit={onSubmit} />;
};

export default NewDeck;
