import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import styled from 'styled-components';
import { ITag } from '../../../types/tag';
import IconButton from '../../IconButton';
import Select, { Option } from '../../Select';
import TooltipWrapper from '../../TooltipWrapper';

const BackLink = styled(IconButton)`
  margin-right: 20px;
`;

const SettingsContainer = styled.div`
  position: absolute;
  top: 100px;
  left: 100px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: row;
    justify-content: space-between;
  }
`;

interface ISettingsProps {
  tag: string;
  allTags: ITag[];
  onTagSelected: (tag: string) => void;
  onBackPressed: () => void;
}

const Settings = (props: ISettingsProps) => {
  const onTagChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.onTagSelected(e.target.value);
  };

  return (
    <SettingsContainer>
      <TooltipWrapper text="Back to Dashboard">
        <BackLink onClick={props.onBackPressed} icon={faArrowLeft} aria-label="Back" />
      </TooltipWrapper>
      <div>
        {props.allTags.length > 0 && (
          <Select defaultValue={props.tag} onChange={onTagChanged}>
            <Option value="">{props.tag === '' ? 'Tag Filter' : 'None'}</Option>
            {props.allTags.map((o) => (
              <Option key={o.id} value={o.id}>
                {o.value}
              </Option>
            ))}
          </Select>
        )}
      </div>
    </SettingsContainer>
  );
};

export default Settings;
