import styled from 'styled-components';

const InputMultiRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-bottom: 30px;
  align-items: center;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    grid-template-columns: 1fr;
  }
`;

export default InputMultiRow;
