import { faGear, faMugHot } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import { CircleFlag } from 'react-circle-flags';
import styled from 'styled-components';
import Button from '../../components/Button';
import ButtonIcon from '../../components/ButtonIcon';
import CardForm from '../../components/CardForm/CardForm';
import Center from '../../components/Center';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import PageContainer from '../../components/PageContainer';
import TextInput from '../../components/TextInput';
import { ICard, IDeck } from '../../state/decks';
import CardRowAndForm from './CardRowAndForm';
import { ICardFormSubmission, IDeckUpdateSubmission } from './models';

const Container = styled.div`
  width: 1200px;
  max-width: 1200px;
  padding: 50px 0;
  box-sizing: border-box;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    padding: 20px;
  }
`;

const CardTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex-wrap: nowrap;
`;

const NewCardForm = styled.div`
  padding: 50px;
  background: ${(props) => props.theme.colors.backgroundCard};
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;
  margin-bottom: 50px;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    padding: 20px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    margin-bottom: 20px;
  }
`;

const FlagContainer = styled.div`
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
`;

const HeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 50px;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
`;

const PracticeButtonLink = styled(Button)`
  margin-left: 10px;
`;

const HighlightedText = styled.span`
  color: ${(props) => props.theme.colors.accent};
`;

const MuffledText = styled.span`
  color: ${(props) => props.theme.colors.foregroundDim};
`;

const TableFooter = styled.div`
  margin-top: 20px;
  text-align: right;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;

  margin-bottom: 50px;
`;

interface IDeckDetailsProps {
  deck: IDeck | undefined;
  cards: ICard[];
  loading: boolean;
  allCards: ICard[];
  newCard: (data: ICardFormSubmission) => Promise<void>;
  updateDeck: (data: IDeckUpdateSubmission) => Promise<void>;
  navigateDashboard: () => void;
  navigatePractice: () => void;
  navigateDeckSettings: () => void;
  onAudioClicked: (literal: string) => Promise<Blob>;
  onRomanizeClicked: (literal: string) => Promise<string>;
  onTranslateClicked: (translation: string) => Promise<string>;
  onEditCard: (id: string, data: ICardFormSubmission) => Promise<void>;
  onDeleteCard: (id: string) => Promise<void>;
  onSearchChanged: (text: string) => void;
}

const DeckDetails = (props: IDeckDetailsProps) => {
  const [editingName, setEditingName] = React.useState(false);

  const onHeaderClick = React.useCallback(() => {
    setEditingName(true);
  }, []);

  const onDeckNameInput = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') {
      evt.preventDefault();
      const newName = (evt.target as HTMLInputElement).value;
      if (newName) {
        props.updateDeck({ name: newName });
      }
      setEditingName(false);
    }
  };

  const onDeckNameBlur = React.useCallback((evt: React.FocusEvent<HTMLInputElement>) => {
    setEditingName(false);
  }, []);

  return (
    <PageContainer>
      <Center>
        <Container>
          {props.deck ? (
            <>
              <HeaderArea>
                <HeaderContainer>
                  <FlagContainer>
                    <CircleFlag countryCode={props.deck.iso_2} height="40px" />
                  </FlagContainer>
                  {!editingName ? (
                    <Header size="lg" nomargin onClick={onHeaderClick}>
                      {props.deck.name}
                    </Header>
                  ) : (
                    <TextInput
                      autoFocus
                      defaultValue={props.deck.name}
                      placeholder="Deck Name"
                      onKeyDown={onDeckNameInput}
                      onBlur={onDeckNameBlur}
                    />
                  )}
                </HeaderContainer>
                <ButtonsContainer>
                  <Button onClick={() => props.navigateDeckSettings()}>
                    <ButtonIcon icon={faGear} />
                    Settings
                  </Button>
                  <PracticeButtonLink onClick={() => props.navigatePractice()} disabled={!props.cards.length}>
                    <ButtonIcon icon={faMugHot} />
                    Practice
                  </PracticeButtonLink>
                </ButtonsContainer>
              </HeaderArea>
              <NewCardForm>
                <Header size="md">New Card</Header>
                <CardForm
                  clearOnSubmit
                  hasPronunciation={props.deck.hasPronunciation}
                  onSubmit={props.newCard}
                  defaultLiteral={props.deck.defaultLiteral}
                  defaultPronunciation={props.deck.defaultPronunciation}
                  romanizationMethod={props.deck.romanizationMethod}
                  hasTTS={props.deck.hasTTS}
                  onAudioClicked={props.onAudioClicked}
                  onRomanizeClicked={props.onRomanizeClicked}
                  onTranslateClicked={props.onTranslateClicked}
                />
              </NewCardForm>
              <SearchContainer onChange={(event) => props.onSearchChanged((event.target as HTMLInputElement).value)}>
                <TextInput placeholder="Search..." />
              </SearchContainer>
              {props.loading ? (
                <Loader />
              ) : props.cards.length > 0 ? (
                <>
                  <CardTable>
                    {props.cards &&
                      props.deck &&
                      props.cards.map((card) => (
                        <CardRowAndForm
                          key={card.id}
                          card={card}
                          deck={props.deck!}
                          onAudioClicked={props.onAudioClicked}
                          onTranslateClicked={props.onTranslateClicked}
                          onRomanizeClicked={props.onRomanizeClicked}
                          onDeleteCard={props.onDeleteCard}
                          onEditCard={props.onEditCard}
                        />
                      ))}
                    <TableFooter>
                      <MuffledText>
                        {props.cards.length === props.allCards.length
                          ? `${props.cards.length} ${props.cards.length === 1 ? 'card' : 'cards'}.`
                          : `${props.allCards.length} ${props.cards.length === 1 ? 'card' : 'cards'} (${props.cards.length} displayed).`}
                      </MuffledText>
                    </TableFooter>
                  </CardTable>
                </>
              ) : props.allCards.length > 0 ? (
                <div>
                  <MuffledText>No results.</MuffledText>
                </div>
              ) : (
                <div>
                  <p>Now that you&apos;ve created a deck, add your first card above.</p>
                  <p>
                    <HighlightedText>Literal</HighlightedText> is the word in your target language.
                  </p>
                  {props.deck.hasPronunciation && (
                    <p>
                      <HighlightedText>Pronunciation</HighlightedText> (optional) is a phonetic pronunciation of the word.
                    </p>
                  )}
                  <p>
                    <HighlightedText>Translation</HighlightedText> is the word in your native language.
                  </p>
                  <p>
                    Press <HighlightedText>Practice</HighlightedText> after you&apos;ve added some cards to get memorizing!
                  </p>
                </div>
              )}
            </>
          ) : props.loading ? (
            <Loader />
          ) : (
            <div>
              No deck found. <a onClick={() => props.navigateDashboard()}>Back to Decks</a>
            </div>
          )}
        </Container>
      </Center>
    </PageContainer>
  );
};

export default DeckDetails;
