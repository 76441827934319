import styled from 'styled-components';

interface IPageContainerProps {
  centered?: boolean;
}

const PageContainer = styled.div<IPageContainerProps>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: ${(props) => (props.centered ? 'center' : 'flex-start')};
  align-items: flex-start;
`;

export default PageContainer;
