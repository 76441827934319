import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { getButtonColor, IButtonProps } from '../Button';

interface IIconButtonElementProps extends IButtonProps {
  borderless?: boolean;
  big?: boolean;
  marginLeft?: boolean;
}

const IconButtonCSS = css<IIconButtonElementProps>`
box-sizing: border-box;

display: flex;
justify-content: center;
align-items: center;
flex-wrap: nowrap;
width: ${(props) => (props.borderless ? '24px' : '40px')};
height: ${(props) => (props.borderless ? '24px' : '40px')};

background: ${(props) => props.theme.colors.backgroundInput};
border-radius: 20px;
border: ${(props) => (props.borderless ? 'none' : `1px solid ${props.theme.colors.border}`)};
outline: 0;
color: ${(props) => props.theme.colors.foreground};

margin-left: ${(props) => (props.marginLeft ? 10 : 0)}px;

font-size: ${(props) => (props.big ? '1.2em' : '1em')};

&:not(:disabled) {
  cursor: pointer;

  &:hover,
  &:active {
    background: ${(props) => (props.borderless ? props.theme.colors.backgroundInput : getButtonColor(props))};
    color: ${(props) => (props.borderless ? getButtonColor(props) : props.theme.colors.accentForeground)}};
  }

  &:focus {
    border: 1px solid ${(props) => getButtonColor(props)};
    background: ${(props) => props.theme.colors.backgroundHighlight};
  }
}

&:disabled {
  color: ${(props) => props.theme.colors.foregroundDim};
}
`;

const IconButtonElement = styled.button<IIconButtonElementProps>`
  ${IconButtonCSS}
`;

const IconButtonLinkElement = styled(Link)<IIconButtonElementProps>`
  ${IconButtonCSS}
`;

type TIconButtonBaseProps = {
  icon: IconProp;
  borderless?: boolean;
  danger?: boolean;
  big?: boolean;
  marginLeft?: boolean;
};

interface IIconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, TIconButtonBaseProps {}

const IconButton: React.FC<IIconButtonProps> = (props) => {
  return (
    <IconButtonElement {...props}>
      <FontAwesomeIcon icon={props.icon} />
    </IconButtonElement>
  );
};

export default IconButton;

interface IIconButtonLinkProps extends LinkProps, TIconButtonBaseProps {}

export const IconButtonLink: React.FC<IIconButtonLinkProps> = (props) => {
  return (
    <IconButtonLinkElement {...props}>
      <FontAwesomeIcon icon={props.icon} />
    </IconButtonLinkElement>
  );
};
