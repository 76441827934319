import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import Landing from './pages/Landing';
import GlobalStyle, { LightTheme, Theme } from './style';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Landing/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import DeckDetails from './pages/DeckDetails';
import DeckList from './pages/DeckList';
import Settings from './pages/Settings';
import NewDeck from './pages/NewDeck';
import Training from './pages/Training';
import useAuthState from './state/auth';
import Auth from './api/auth';
import { IsMock } from './utils/mock';
import useThemeState from './state/theme';
import { toast, ToastBar, Toaster } from 'react-hot-toast';
import { ErrorBoundary } from './utils/errors';
import DeckSettings from './pages/DeckSettings';
import AnkiImport from './pages/DeckSettings/Anki';
import RouteWrapper from './components/RouteWrapper';
import useOnline from './utils/online';

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <RouteWrapper title="Flashcards for language-learning">
              <Landing />
            </RouteWrapper>
          }
        >
          <Route index element={<Home />} />
        </Route>
        <Route
          path="/login"
          element={
            <RouteWrapper title="Login">
              <Login />
            </RouteWrapper>
          }
        />
        <Route
          path="/register"
          element={
            <RouteWrapper title="Register">
              <Register />
            </RouteWrapper>
          }
        />
        <Route
          path="/dashboard"
          element={
            <RouteWrapper title="Dashboard">
              <Dashboard />
            </RouteWrapper>
          }
        >
          <Route index element={<DeckList />} />
          <Route path="/dashboard/settings" element={<Settings />} />
          <Route path="/dashboard/decks/new" element={<NewDeck />} />
          <Route path="/dashboard/decks/:id/settings" element={<DeckSettings />} />
          <Route path="/dashboard/decks/:id/anki" element={<AnkiImport />} />
          <Route path="/dashboard/decks/:id" element={<DeckDetails />} />
        </Route>
        <Route
          path="/training/:id"
          element={
            <RouteWrapper title="Practice">
              <Training />
            </RouteWrapper>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

interface IAppProps {}

const App: React.FC<IAppProps> = (props) => {
  const theme = useThemeState();
  const themeContext = theme.Theme === 'dark' ? Theme : LightTheme;
  const auth = useAuthState();
  const [loadingUser, setLoadingUser] = React.useState(true);

  React.useEffect(() => {
    if (localStorage.getItem('user') && !IsMock()) {
      const user = JSON.parse(localStorage.getItem('user')!);
      Auth.GetMe(user.token).then((user) => {
        auth.SetUser(user);
        setLoadingUser(false);
      });
    } else {
      if (IsMock()) {
        Auth.GetMe('abcd1234')
          .then((user) => {
            auth.SetUser(user);
            setLoadingUser(false);
          })
          .catch((err: any) => {
            console.error(err);
          });
      } else {
        setLoadingUser(false);
      }
    }
  }, []);

  const online = useOnline();
  const [lastOnline, setLastOnline] = React.useState(online);
  React.useEffect(() => {
    if (online && !lastOnline) {
      toast.success('You are now online.', { duration: 3000 });
      setLastOnline(true);
    } else if (!online && lastOnline) {
      toast.error('You have gone offline.', { duration: 3000 });
      setLastOnline(false);
    }
  }, [online, lastOnline]);

  return (
    <ErrorBoundary>
      <ThemeProvider theme={themeContext}>
        <GlobalStyle />
        {!loadingUser && <AppRoutes />}
        <Toaster
          position="bottom-center"
          toastOptions={{
            style: {
              background: themeContext.colors.background,
              border: '1px solid ' + themeContext.colors.border,
              borderRadius: '8px',
              color: themeContext.colors.foreground,
            },
          }}
        >
          {(t) => (
            <ToastBar
              toast={t}
              style={{
                ...t.style,
                // animation: 'none',
              }}
            />
          )}
        </Toaster>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
