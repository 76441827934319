export const TTS = {
  Play: (blob: Blob) =>
    new Promise((res, rej) => {
      const a = new FileReader();
      a.onload = (e) => {
        const dataUrl = e.target!.result as string;
        const audio = new Audio();
        audio.crossOrigin = 'anonymous';
        audio.src = dataUrl;
        audio.load();
        audio.play().then(res).catch(rej);
      };
      a.onerror = (e) => rej(e);
      a.readAsDataURL(blob);
    }),
};
