import * as React from 'react';
import { Outlet } from 'react-router-dom';

interface ILandingProps extends React.PropsWithChildren {}

const Landing: React.FC<ILandingProps> = (props) => {
  return <Outlet />;
};

export default Landing;
