import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Theme } from '../../style';

export interface IButtonProps {
  danger?: boolean;
  marginLeft?: boolean;
  disabled?: boolean;
}

export const BaseButtonCSS = css<IButtonProps>`
  padding: 0 20px;
  box-sizing: border-box;
  height: 40px;
  outline: 0;
  line-height: 32px;
  background: ${(props) => props.theme.colors.backgroundInput};
  color: ${(props) => props.theme.colors.foreground};
  text-decoration: none;
  font-size: 13.333px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => (props.marginLeft ? 10 : 0)}px;

  &:not(:disabled) {
    cursor: pointer;
  }
  ${(props) => (props.disabled ? 'cursor: initial;' : null)}
  ${(props) => (props.disabled ? 'pointer-events: none;' : null)}
`;

export const getButtonColor = (props: IButtonProps & { theme: typeof Theme }) => {
  if (props.danger) {
    return props.theme.colors.danger;
  }

  return props.theme.colors.accent;
};

const Button = styled.button<IButtonProps>`
  ${BaseButtonCSS}
  border-radius: 20px;
  border: none;

  &:hover,
  &:active {
    &:not(:disabled) {
      background: ${(props) => (!props.disabled ? getButtonColor(props) : 'initial')};
      color: ${(props) => (!props.disabled ? props.theme.colors.accentForeground : props.theme.colors.foreground)};
    }
  }

  &:focus {
    background: ${(props) => props.theme.colors.backgroundHighlight};
  }

  ${(props) => (props.disabled ? 'opacity: 0.5;' : null)}
  &:disabled {
    opacity: 0.5;
  }
`;

export default Button;

export const ButtonLink = Button.withComponent(Link);
