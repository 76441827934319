import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthState from '../../state/auth';
import useThemeState from '../../state/theme';
import FeedbackModal from './FeedbackModal';
import HeaderStateless from '../../components/DashboardHeader/DashboardHeaderStateless';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const authState = useAuthState();
  const theme = useThemeState();

  const logout = () => {
    authState.SetUser(undefined);
    localStorage.removeItem('mock');
    navigate(`/login`);
  };

  const settings = () => {
    navigate('/dashboard/settings');
  };

  const home = () => {
    navigate('/dashboard');
  };

  const toggleTheme = () => theme.SetTheme(theme.Theme === 'dark' ? 'light' : 'dark');

  const [feedbackOpen, setFeedbackOpen] = React.useState(false);
  const toggleFeedback = React.useCallback(() => {
    setFeedbackOpen(!feedbackOpen);
  }, [feedbackOpen]);
  const onFeedbackSubmit = React.useCallback(() => {
    setFeedbackOpen(false);
  }, []);

  let userName = authState.User?.name || '';

  return (
    <>
      {feedbackOpen && <FeedbackModal onSubmit={onFeedbackSubmit} />}
      <HeaderStateless
        onLogoutClicked={logout}
        onThemeToggleClicked={toggleTheme}
        toggleFeedback={toggleFeedback}
        onSettingsClicked={settings}
        onHomeClicked={home}
        userName={userName}
      />
    </>
  );
};

export default Header;
