import * as React from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Decks from '../../../api/decks';
import useAuthState from '../../../state/auth';
import useDecksState from '../../../state/decks';
import Stateless from './index.stateless';
import { IAnkiDeckColumnForm } from './models';

const AnkiImport = () => {
  const auth = useAuthState();
  const decks = useDecksState();
  const params = useParams<{ id: string }>();
  const id = params.id!;
  const deck = decks.Decks.find((v) => v.id === id);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const [loadingDeck, setLoadingDeck] = React.useState(false);
  React.useEffect(() => {
    if (auth.User?.token && !deck) {
      setLoadingDeck(true);
      Decks.GetDecks(auth.User.token)
        .then((data) => decks.SetDecks(data))
        .finally(() => setLoadingDeck(false));
    }
  }, [auth.User?.token, deck]);

  const navigateSettings = () => navigate(`/dashboard/decks/${deck!.id}/settings`);

  const [columns, setColumns] = React.useState<string[]>([]);

  const [loadingColumns, setLoadingColumns] = React.useState(false);

  const onFileSelected = async (f: File) => {
    setLoadingColumns(true);
    const columns = await Decks.GetAnkiColumns(auth.User?.token || '', f);
    setLoadingColumns(false);
    setColumns(columns);
  };

  const onSubmit = async (f: File, data: IAnkiDeckColumnForm) => {
    setLoading(true);
    const uploadPromise = Decks.UploadAnki(auth.User?.token || '', id, f, data);

    toast.promise(uploadPromise, {
      loading: 'Uploading...',
      success: 'Imported.',
      error: 'Failed to import Anki deck. Try again later.',
    });
    await uploadPromise;
    const newCards = await Decks.GetCards(auth.User?.token || '', id);
    decks.SetCards(id, newCards);
    setLoading(false);
    navigate(`/dashboard/decks/${id}`);
  };

  return (
    <Stateless
      deck={deck}
      loading={loading || loadingDeck}
      loadingColumns={loadingColumns}
      navigateSettings={navigateSettings}
      onFileSelected={onFileSelected}
      possibleColumns={columns}
      onSubmit={onSubmit}
      hasPronunciation={deck?.hasPronunciation || false}
    />
  );
};

export default AnkiImport;
