import { createGlobalStyle } from 'styled-components';
import { mix } from 'color2k';

const ACCENT_COLOR = '#e2654f';

export const Theme = {
  colors: {
    background: '#222222',
    backgroundInput: '#2e2e2e',
    backgroundCard: '#171717',
    backgroundHighlight: '#333333',
    foreground: '#fff',
    foregroundDim: '#888',
    accent: ACCENT_COLOR,
    border: 'transparent',
    borderHighlight: 'transparent',
    error: '#b14646',
    warning: '#b8740e',
    danger: '#882323',
    accentForeground: '#fff',
    accentForegroundDim: mix('#fff', ACCENT_COLOR, 0.2),
    hint: '#444',
    success: '#7ae616',
  },
  table: {
    border: '1px solid #333',
  },
  header: {
    border: '1px solid #333',
  },
  fonts: {
    header: 'Barlow',
    body: 'Heebo',
  },
  responsive: {
    phone: 600,
    phoneLandscape: 1200,
  },
};

export const LightTheme: typeof Theme = {
  ...Theme,
  colors: {
    background: '#fff',
    backgroundInput: '#fff',
    backgroundCard: '#fff',
    backgroundHighlight: '#eee',
    foreground: '#333',
    foregroundDim: '#888',
    accent: ACCENT_COLOR,
    border: '#bbbbbb',
    borderHighlight: '#aaaaaa',
    error: '#b14646',
    warning: '#b8740e',
    danger: '#882323',
    accentForeground: '#fff',
    accentForegroundDim: mix('#fff', ACCENT_COLOR, 0.2),
    hint: '#ccc',
    success: '#5ba516',
  },
  table: {
    border: '1px solid #bbbbbb',
  },
  header: {
    border: '1px solid #bbbbbb',
  },
};

const GlobalStyle = createGlobalStyle<{ theme: typeof Theme }>`
  html, body {
    padding: 0;
    margin: 0;
    background-color: ${(props) => props.theme.colors.background};
  }

  html, body, input, textarea, select, option, button {
    font-family: ${(props) => props.theme.fonts.body}, sans-serif;
    color: ${(props) => props.theme.colors.foreground};
  }

  a {
    color: ${(props) => props.theme.colors.accent};
  }
`;

export default GlobalStyle;
