import * as React from 'react';

interface IRouteWrapperProps {
  title: string;
}

const RouteWrapper: React.FC<React.PropsWithChildren<IRouteWrapperProps>> = (props) => {
  React.useEffect(() => {
    document.title = 'Pomelo | ' + props.title;
  }, [props.title]);

  return <>{props.children}</>;
};

export default RouteWrapper;
