import { faArrowRight, faCheck, faCross, faTimes, faVolumeDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Center from '../../components/Center';
import IconButton from '../../components/IconButton';
import Loader, { SmallLoader } from '../../components/Loader';
import PageContainer from '../../components/PageContainer';
import { TTrainingResponseDifficulty } from '../../types/training';
import { IsMock } from '../../utils/mock';
import { TTS } from '../../utils/tts';
import Settings from '../../components/Training/Settings';
import KeyboardShortcuts from '../../components/Training/KeyboardShortcuts';
import { ITag } from '../../types/tag';
import Markdown from 'markdown-to-jsx';

const CardSubject = styled.div`
  font-size: 3em;
  font-family: ${(props) => props.theme.fonts.header};
`;

const CardLiteral = styled.div`
  font-size: 3em;
  font-family: ${(props) => props.theme.fonts.header};
  color: ${(props) => props.theme.colors.foregroundDim};
`;

const CardPronunciation = styled.div`
  font-size: 1.3em;
  font-family: ${(props) => props.theme.fonts.header};
  color: ${(props) => props.theme.colors.foregroundDim};
`;

const CardNote = styled.div`
  margin-top: 10px;
  font-size: 0.9em;
  color: ${(props) => props.theme.colors.foregroundDim};
  max-width: 300px;
  white-space: pre;
`;

const FlipButton = styled(Button)`
  font-size: 1.2em;
  width: 150px;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    width: 100%;
  }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-direction: column;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 20px;
`;

const ResponseContainer = styled.div`
  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  @media screen and (max-width: ${(props) => props.theme.responsive.phone}px) {
    gap: 50px;
    grid-template-columns: 1fr;
  }
`;

const ResponseButtons = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    margin-top: 20px;
  }
`;

const ResponseButton = styled(Button)`
  width: 150px;
  &:not(:last-child) {
    margin-right: 20px;
  }

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    width: 100%;
  }
`;

const TrainingCard = styled.div`
  background: transparent;
  padding: 50px;
  box-sizing: border-box;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    padding: 30px;
    width: 100%;
    flex-grow: 1;
  }
`;

export interface ITrainingCard {
  cardId: string;
  front: string;
  frontSub: string;
  back: string;
  backSub: string;
  literal: string;
  literalOnFront: boolean;
  notes?: string;
  streak: number;
  easiness: number;
  hasTTS: boolean;
}

interface ITrainingProps {
  activeTag: string;
  trainingCard: ITrainingCard | undefined;
  flipped: boolean;
  showKeybinds: boolean;
  loading: boolean;
  allTags: ITag[];
  getNewCard: () => Promise<void>;
  onSubmit: (difficulty: TTrainingResponseDifficulty) => Promise<void>;
  getCurrentTTS: () => Promise<Blob>;
  onTagSelected: (tag: string) => void;
  back: () => void;
  flip: () => void;
  online: boolean;
}

const Training = (props: ITrainingProps) => {
  const [playAudioLoading, setPlayAudioLoading] = React.useState(false);

  const onConfirm = (event: React.MouseEvent<HTMLButtonElement>, difficulty: TTrainingResponseDifficulty) => {
    event.preventDefault();

    props.onSubmit(difficulty);
  };

  const playAudio = () => {
    setPlayAudioLoading(true);
    props
      .getCurrentTTS()
      .then((blob) => TTS.Play(blob))
      .finally(() => setPlayAudioLoading(false));
  };

  React.useEffect(() => {
    if (props.flipped && !IsMock() && props.trainingCard?.hasTTS && props.online) {
      playAudio();
    }
  }, [props.flipped, props.online]);

  return (
    <PageContainer>
      {props.showKeybinds && <KeyboardShortcuts />}
      <Settings tag={props.activeTag} allTags={props.allTags} onTagSelected={props.onTagSelected} onBackPressed={props.back} />
      <Center verticalStretch>
        {props.loading ? (
          <Loader />
        ) : (
          <CardContainer>
            <TrainingCard>
              {props.trainingCard !== undefined ? (
                props.flipped ? (
                  <ResponseContainer>
                    <div>
                      {props.trainingCard.hasTTS && !props.trainingCard.literalOnFront && (
                        <ActionButtons>
                          {playAudioLoading ? (
                            <SmallLoader />
                          ) : (
                            <IconButton big icon={faVolumeDown} onClick={playAudio} disabled={!props.online} />
                          )}
                        </ActionButtons>
                      )}
                      <CardSubject>
                        <Markdown>{props.trainingCard.back}</Markdown>
                      </CardSubject>
                      {props.trainingCard.literalOnFront && (
                        <CardLiteral>
                          <Markdown>{props.trainingCard.front}</Markdown>
                        </CardLiteral>
                      )}
                      {props.trainingCard.backSub && (
                        <CardPronunciation>
                          <Markdown>{props.trainingCard.backSub}</Markdown>
                        </CardPronunciation>
                      )}
                      {props.trainingCard.notes && (
                        <CardNote>
                          <Markdown>{props.trainingCard.notes}</Markdown>
                        </CardNote>
                      )}
                    </div>
                    <ResponseButtons>
                      <ResponseButton
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => onConfirm(event, 0)}
                        disabled={!props.online}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </ResponseButton>
                      {/*<Button onClick={(event: React.MouseEvent<HTMLButtonElement>) => onConfirm(event, 2)}>Incorrect, Familiar</Button>
                      <Button onClick={(event: React.MouseEvent<HTMLButtonElement>) => onConfirm(event, 3)}>Correct, Hard</Button>*/}
                      <ResponseButton
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => onConfirm(event, 5)}
                        disabled={!props.online}
                      >
                        <FontAwesomeIcon icon={faCheck} />
                      </ResponseButton>
                    </ResponseButtons>
                  </ResponseContainer>
                ) : (
                  <>
                    {props.trainingCard.hasTTS && (
                      <ActionButtons>
                        {props.trainingCard.literalOnFront &&
                          (playAudioLoading ? (
                            <SmallLoader />
                          ) : (
                            <IconButton icon={faVolumeDown} onClick={playAudio} aria-label="Play audio" disabled={!props.online} />
                          ))}
                      </ActionButtons>
                    )}
                    <CardSubject>
                      <Markdown>{props.trainingCard.front}</Markdown>
                    </CardSubject>
                    {props.trainingCard.frontSub && (
                      <CardPronunciation>
                        <Markdown>{props.trainingCard.frontSub}</Markdown>
                      </CardPronunciation>
                    )}
                    <ResponseButtons>
                      <FlipButton onClick={() => props.flip()}>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </FlipButton>
                    </ResponseButtons>
                  </>
                )
              ) : (
                <div>This deck has no cards. Go back and add some!</div>
              )}
            </TrainingCard>
          </CardContainer>
        )}
      </Center>
    </PageContainer>
  );
};

export default Training;
