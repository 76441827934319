import * as React from 'react';
import styled, { css } from 'styled-components';
import { faBarsStaggered, faDoorOpen, faGear, faLightbulb, faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IsMock } from '../../utils/mock';
import TooltipWrapper from '../TooltipWrapper';
import { Link } from 'react-router-dom';

const MockNotice = styled.div`
  font-size: 0.8em;
  margin-left: 20px;
  color: ${(props) => props.theme.colors.foregroundDim};

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    display: none;
  }
`;

const HeaderRow = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;

  height: 50px;
  border-bottom: ${(props) => props.theme.header.border};
`;

const HeaderLinks = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const HeaderLinkItem = styled.li`
  margin: 0;
  padding: 0;
`;

const HeaderLinkCSS = css`
  color: ${(props) => props.theme.colors.foreground};
  background: ${(props) => props.theme.colors.background};
  border: none;
  text-decoration: none;
  padding: 0 10px;
  box-sizing: border-box;
  height: 50px;
  line-height: 50px;
  display: block;
  cursor: pointer;
  outline: 0;

  &:focus {
    background: ${(props) => props.theme.colors.backgroundHighlight};
  }
`;

const HeaderLink = styled(Link)`
  ${HeaderLinkCSS}
`;

const HeaderLinkIcon = styled(HeaderLink)``;

const HeaderButtonIcon = styled.button`
  ${HeaderLinkCSS}
`;

const HeaderHello = styled.div`
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid ${(props) => props.theme.colors.border};
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.foregroundDim};

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    display: none;
  }
`;

const HeaderRightLinks = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
`;

const HeaderContainer = styled.div`
  position: relative;
`;

interface IHeaderStatelessProps {
  onLogoutClicked: () => void;
  onThemeToggleClicked: () => void;
  toggleFeedback: () => void;
  onSettingsClicked: () => void;
  onHomeClicked: () => void;
  userName: string;
}

const HeaderStateless: React.FC<IHeaderStatelessProps> = (props) => {
  const logout = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.onLogoutClicked();
  };

  const toggleTheme = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.onThemeToggleClicked();
  };

  const toggleFeedback = () => {
    props.toggleFeedback();
  };

  const settings = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.onSettingsClicked();
  };

  const home = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.onHomeClicked();
  };

  return (
    <HeaderContainer>
      <HeaderRow role="toolbar">
        <div>
          <HeaderLinks>
            <TooltipWrapper text="Dashboard">
              <HeaderLinkItem>
                <HeaderButtonIcon onClick={home}>
                  <FontAwesomeIcon icon={faBarsStaggered} />
                </HeaderButtonIcon>
              </HeaderLinkItem>
            </TooltipWrapper>
            <TooltipWrapper text="Feedback">
              <HeaderLinkItem>
                <HeaderButtonIcon onClick={toggleFeedback}>
                  <FontAwesomeIcon icon={faMessage} />
                </HeaderButtonIcon>
              </HeaderLinkItem>
            </TooltipWrapper>
            {IsMock() && <MockNotice>You are in demo mode. Your changes will not be saved.</MockNotice>}
          </HeaderLinks>
        </div>
        <HeaderRightLinks>
          {props.userName && <HeaderHello>Hi, {props.userName}</HeaderHello>}
          <TooltipWrapper text="Toggle light vs. dark mode">
            <HeaderButtonIcon onClick={toggleTheme}>
              <FontAwesomeIcon icon={faLightbulb} />
            </HeaderButtonIcon>
          </TooltipWrapper>
          <TooltipWrapper text="Settings">
            <HeaderButtonIcon onClick={settings} aria-label="Settings">
              <FontAwesomeIcon icon={faGear} />
            </HeaderButtonIcon>
          </TooltipWrapper>
          <TooltipWrapper text="Logout">
            <HeaderButtonIcon onClick={logout} aria-label="Logout">
              <FontAwesomeIcon icon={faDoorOpen} />
            </HeaderButtonIcon>
          </TooltipWrapper>
        </HeaderRightLinks>
      </HeaderRow>
    </HeaderContainer>
  );
};

export default HeaderStateless;
