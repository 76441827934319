import * as React from 'react';
import styled from 'styled-components';
import { ITag } from '../../types/tag';
import { GetTagColor, GetTagForeground } from '../../utils/cyrb53';

const TagContainer = styled.div`
  border-radius: 8px;
  background: ${(props) => props.theme.colors.backgroundInput};
  border: 1px solid ${(props) => props.theme.colors.border};
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 4px;
`;

interface ITagProps {
  tagColor: string;
  tagForeground: string;
}

export const Tag = styled.div<ITagProps>`
  display: block;
  background: ${(props) => props.tagColor};
  color: ${(props) => props.tagForeground};
  border-radius: 4px;
  padding: 4px;
  margin-right: 10px;
  font-size: 0.8em;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

interface ITagCloudProps {
  tags: ITag[];
  onTagClicked?: (index: number) => void;
}

const TagCloud = (props: ITagCloudProps) => {
  return (
    <TagContainer>
      {props.tags.map((tag, index) => (
        <Tag
          key={tag.id}
          onClick={() => (props.onTagClicked ? props.onTagClicked(index) : null)}
          tagColor={GetTagColor(tag.value)}
          tagForeground={GetTagForeground(tag.value)}
        >
          {tag.value}
        </Tag>
      ))}
    </TagContainer>
  );
};

export default TagCloud;
