import styled from 'styled-components';

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
`;

export default SpaceBetween;
