import create from 'zustand';

export interface ILanguage {
  id: string;
  name: string;
  hasPronunciation: boolean;
  flag: string;
  iso_2: string;
  iso_3: string;
  locale: string;
  defaultLiteral: string;
  defaultPronunciation: string;
  romanizationMethod: string;
  hasTTS: boolean;
}

interface ILanguagesState {
  Languages: ILanguage[];
  SetLanguages: (languages: ILanguage[]) => void;
}

const useLanguagesState = create<ILanguagesState>((set) => ({
  Languages: [],
  SetLanguages: (langs) => set((state) => ({ ...state, Languages: langs })),
}));

export default useLanguagesState;
