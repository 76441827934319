import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Decks from '../../api/decks';
import useAuthState from '../../state/auth';
import useDecksState, { ICard } from '../../state/decks';
import { IsMock } from '../../utils/mock';
import { ICardFormSubmission, IDeckUpdateSubmission } from './models';
import Stateless from './index.stateless';
import TTS from '../../api/tts';
import Translate, { IRomanizeResponse, ITranslateResponse } from '../../api/translate';
import { IError } from '../Settings/models';
import { Notify } from '../../utils/errors';

interface IDeckDetailsProps {}

const DeckDetails = (props: IDeckDetailsProps) => {
  const auth = useAuthState();
  const decks = useDecksState();
  const params = useParams<{ id: string }>();
  const id = params.id!;
  const deck = decks.Decks.find((v) => v.id === id);
  const navigate = useNavigate();

  const cards = decks.Cards[id] || [];

  const [loadingDeck, setLoadingDeck] = React.useState(!deck);
  React.useEffect(() => {
    if ((auth.User?.token || IsMock()) && !deck) {
      setLoadingDeck(true);
      Decks.GetDecks(auth.User?.token || '')
        .then((data) => {
          setLoadingDeck(false);
          decks.SetDecks(data);
        })
        .catch((err) => {
          plausible('404', { props: { path: document.location.pathname } });
          Notify(err);
        });
    }
  }, [auth.User?.token]);

  const [loading, setLoading] = React.useState(cards.length === 0);
  React.useEffect(() => {
    if ((auth.User?.token || IsMock()) && cards.length === 0) {
      setLoading(true);
      Decks.GetCards(auth.User?.token || '', id).then((data) => {
        setLoading(false);
        decks.SetCards(id, data);
      });
    }
  }, [auth.User?.token, id]);

  const newCard = async (data: ICardFormSubmission) => {
    if (auth.User?.token || IsMock()) {
      plausible('Cards/New');
      const c: ICard = await Decks.CreateCard(auth.User?.token || '', id, data);
      decks.SetCards(id, [c, ...decks.Cards[id]]);
    }
  };

  const updateDeck = async (data: IDeckUpdateSubmission) => {
    plausible('Decks/Update');
    decks.SetDeckName(id, data.name);
    await Decks.UpdateDeck(auth.User?.token || '', id, data);
  };

  const navigateDashboard = () => navigate('/dashboard');
  const navigatePractice = () => navigate(`/training/${deck?.id}`);
  const navigateDeckSettings = () => navigate(`/dashboard/decks/${id}/settings`);
  const onAudioClicked = async (literal: string) => {
    plausible('Cards/Audio');
    return TTS.Get(auth.User!.token, literal, deck?.locale || '');
  };

  const onTranslateClicked = async (translation: string) => {
    const data = await Translate.Get(auth.User!.token, translation, 'en', deck?.locale || '');
    if ((data as IError).error) {
      Notify((data as IError).error);
    }

    plausible('Cards/Translate');

    return (data as ITranslateResponse).literal;
  };

  const onRomanizeClicked = async (literal: string) => {
    const data = await Translate.Romanize(auth.User!.token, literal, deck?.romanizationMethod || '');
    if ((data as IError).error) {
      Notify((data as IError).error);
    }

    plausible('Cards/Romanize');

    return (data as IRomanizeResponse).romanized;
  };

  const updateCard = async (id: string, data: ICardFormSubmission) => {
    if (auth.User?.token) {
      plausible('Cards/Update');

      decks.UpdateCard(deck?.id || '', id, data);
      await Decks.UpdateCard(auth.User.token, deck?.id || '', id, data);
    }
  };

  const deleteCard = async (id: string) => {
    if (auth.User?.token) {
      plausible('Cards/Delete');

      decks.DeleteCard(deck?.id || '', id);
      await Decks.DeleteCard(auth.User.token, deck?.id || '', id);
    }
  };

  const [search, setSearch] = React.useState('');
  const onSearchChanged = (text: string) => {
    setSearch(text.trim());
  };

  const filteredCards = cards.filter((c) => {
    const s = search.trim().toLowerCase();

    if (!s) {
      return true;
    }

    if (c.literal.trim().toLowerCase().includes(s)) {
      return true;
    }

    if (c.pronunciation && c.pronunciation.trim().toLowerCase().includes(s)) {
      return true;
    }

    if (c.translation.trim().toLowerCase().includes(s)) {
      return true;
    }

    for (const tag of c.tags) {
      if (tag.value.trim().toLowerCase().includes(s)) {
        return true;
      }
    }

    return false;
  });

  return (
    <Stateless
      deck={deck}
      cards={filteredCards}
      allCards={cards}
      loading={loading || loadingDeck}
      newCard={newCard}
      updateDeck={updateDeck}
      navigateDashboard={navigateDashboard}
      navigatePractice={navigatePractice}
      navigateDeckSettings={navigateDeckSettings}
      onAudioClicked={onAudioClicked}
      onRomanizeClicked={onRomanizeClicked}
      onTranslateClicked={onTranslateClicked}
      onDeleteCard={deleteCard}
      onEditCard={updateCard}
      onSearchChanged={onSearchChanged}
    />
  );
};

export default DeckDetails;
