import styled, { css } from 'styled-components';

interface ICardProps {
  margin?: boolean;
  noBorder?: boolean;
  fullWidthOnMobile?: boolean;
  flexGrow?: boolean;
}

export const CardCSS = css<ICardProps>`
  background: ${(props) => props.theme.colors.backgroundCard};
  text-align: left;
  outline: none;

  overflow: hidden;

  position: relative;
  padding: 50px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.border};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex-wrap: nowrap;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    border: ${(props) => (props.noBorder ? 'none' : '1px solid ' + props.theme.colors.border)};
    padding: 30px;
    margin: ${(props) => (props.margin ? '20px' : '0')};
    width: ${(props) => (props.fullWidthOnMobile ? '100%' : 'auto')};
    flex-grow: ${(props) => (props.flexGrow ? '1' : 'auto')};
  }
`;

export const Card = styled.div<ICardProps>`
  ${CardCSS}
`;

export const CardButton = styled.button<ICardProps>`
  ${CardCSS}

  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.accent};
  }
`;

export default Card;
