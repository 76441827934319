import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import Button from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import Card from '../../../components/Card';
import Center from '../../../components/Center';
import Header from '../../../components/Header';
import InputRow from '../../../components/InputRow';
import Label from '../../../components/Label';
import Loader from '../../../components/Loader';
import PageContainer from '../../../components/PageContainer';
import Select, { Option } from '../../../components/Select';
import { IDeck } from '../../../state/decks';
import { IAnkiDeckColumnForm } from './models';

const Container = styled.div`
  width: 1200px;
  max-width: 1200px;
  padding: 50px 0;
  box-sizing: border-box;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    padding: 20px;
  }
`;

const SettingsBacklink = styled.a`
  font-size: 0.8em;
  margin-top: 10px;
  margin-bottom: 50px;
  display: block;
  cursor: pointer;
`;

const HiddenFileInput = styled.input`
  display: hidden;
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
  top: -9999px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 50px;
`;

interface IAnkiImportProps {
  deck: IDeck | undefined;
  loading: boolean;
  navigateSettings: () => void;
  onFileSelected: (file: File) => Promise<void>;
  possibleColumns: string[];
  loadingColumns: boolean;
  onSubmit: (file: File, columns: IAnkiDeckColumnForm) => Promise<void>;
  hasPronunciation: boolean;
}

const AnkiImport = (props: IAnkiImportProps) => {
  const importInputRef = React.useRef<HTMLInputElement>(null);
  const startImport = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (importInputRef.current) {
      importInputRef.current.click();
    }
  }, []);

  const [importing, setImporting] = React.useState(false);
  const onImportChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const file = event.target.files ? event.target.files[0] : undefined;
    if (!file) {
      return;
    }

    props.onFileSelected(file);
  };

  const form = useForm<IAnkiDeckColumnForm>();

  const onSubmit = form.handleSubmit((data) => {
    if (!importInputRef.current || !importInputRef.current.files) {
      return;
    }

    props.onSubmit(importInputRef.current.files[0], data);
  });

  return props.loading ? (
    <Loader />
  ) : (
    <PageContainer>
      <Center>
        <Container>
          {props.deck ? (
            <>
              <HiddenFileInput type="file" accept=".apkg" ref={importInputRef} onChange={onImportChanged} />
              <Header size="lg" nomargin>
                Import Anki Deck
              </Header>
              <SettingsBacklink onClick={props.navigateSettings}>Back to {props.deck.name} settings</SettingsBacklink>
              <Card>
                {props.loadingColumns ? (
                  <Loader />
                ) : (
                  <ButtonsContainer>
                    <Button onClick={startImport} disabled={importing}>
                      <ButtonIcon icon={faFileImport} /> Select Anki Deck
                    </Button>
                  </ButtonsContainer>
                )}
                {props.possibleColumns.length > 0 ? (
                  <form onSubmit={onSubmit}>
                    <InputRow>
                      <Label>Literal</Label>
                      <Select {...form.register('literal')}>
                        <Option value="">None</Option>
                        {props.possibleColumns.map((col) => (
                          <Option key={col} value={col}>
                            {col}
                          </Option>
                        ))}
                      </Select>
                    </InputRow>
                    {props.hasPronunciation && (
                      <InputRow>
                        <Label>Pronunciation</Label>
                        <Select {...form.register('pronunciation')}>
                          <Option value="">None</Option>
                          {props.possibleColumns.map((col) => (
                            <Option key={col} value={col}>
                              {col}
                            </Option>
                          ))}
                        </Select>
                      </InputRow>
                    )}
                    <InputRow>
                      <Label>Translation</Label>
                      <Select {...form.register('translation')}>
                        <Option value="">None</Option>
                        {props.possibleColumns.map((col) => (
                          <Option key={col} value={col}>
                            {col}
                          </Option>
                        ))}
                      </Select>
                    </InputRow>
                    <InputRow>
                      <Label>Example Literal</Label>
                      <Select {...form.register('exampleLiteral')}>
                        <Option value="">None</Option>
                        {props.possibleColumns.map((col) => (
                          <Option key={col} value={col}>
                            {col}
                          </Option>
                        ))}
                      </Select>
                    </InputRow>
                    {props.hasPronunciation && (
                      <InputRow>
                        <Label>Example Phonetic</Label>
                        <Select {...form.register('examplePhonetic')}>
                          <Option value="">None</Option>
                          {props.possibleColumns.map((col) => (
                            <Option key={col} value={col}>
                              {col}
                            </Option>
                          ))}
                        </Select>
                      </InputRow>
                    )}
                    <InputRow>
                      <Label>Example Translation</Label>
                      <Select {...form.register('exampleTranslation')}>
                        <Option value="">None</Option>
                        {props.possibleColumns.map((col) => (
                          <Option key={col} value={col}>
                            {col}
                          </Option>
                        ))}
                      </Select>
                    </InputRow>
                    <Button type="submit">Upload</Button>
                  </form>
                ) : null}
              </Card>
            </>
          ) : (
            <div>No deck found.</div>
          )}
        </Container>
      </Center>
    </PageContainer>
  );
};

export default AnkiImport;
