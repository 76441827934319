import * as React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Center from '../../components/Center';
import PageContainer from '../../components/PageContainer';
import { IsMock } from '../../utils/mock';
import Header from './DashboardHeader';

const DashboardContainer = styled.div`
  width: 1200px;
  max-width: 1200px;
`;

const Content = styled.div`
  position: relative;
  padding: 50px 0;
  box-sizing: border-box;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    padding: 30px;
  }
`;

const Dashboard: React.FC = () => {
  React.useEffect(() => {
    plausible('Dashboard/View', { props: { IsMock: IsMock() ? 'true' : 'false' } });
  }, []);

  return (
    <PageContainer>
      <Center>
        <DashboardContainer>
          <Header />
          <Content>
            <Outlet />
          </Content>
        </DashboardContainer>
      </Center>
    </PageContainer>
  );
};

export default Dashboard;
