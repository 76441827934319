import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Decks from '../../api/decks';
import useAuthState from '../../state/auth';
import useDecksState, { IDeck } from '../../state/decks';
import { IsMock } from '../../utils/mock';
import Stateless from './index.stateless';

const DeckList: React.FC = () => {
  const auth = useAuthState();
  const decks = useDecksState();
  const navigate = useNavigate();
  const { search } = useLocation();

  const [loadingDeck, setLoadingDeck] = React.useState(decks.Decks.length === 0);
  const [decksError, setDecksError] = React.useState('');
  React.useEffect(() => {
    if ((auth.User?.token || IsMock()) && decks.Decks.length === 0) {
      setLoadingDeck(true);
      Decks.GetDecks(auth.User?.token || '')
        .then((data: IDeck[]) => {
          decks.SetDecks(data);
        })
        .catch((err) => {
          setDecksError("Couldn't load your decks right now. It looks like the Pomelo servers are down.");
        })
        .finally(() => {
          setLoadingDeck(false);
        });
    }
  }, [auth.User?.token]);

  const onDeckClick = React.useCallback(
    (id: string) => {
      navigate(`/dashboard/decks/${id}${search}`);
    },
    [search]
  );

  const onNewDeck = () => navigate(`/dashboard/decks/new`);

  return <Stateless loading={loadingDeck} decksError={decksError} decks={decks.Decks} onSelectDeck={onDeckClick} onNewDeck={onNewDeck} />;
};

export default DeckList;
