import create from 'zustand';

interface IThemeState {
  Theme: 'light' | 'dark';
  SetTheme: (theme: 'light' | 'dark') => void;
}

const useThemeState = create<IThemeState>((set) => ({
  Theme: localStorage.getItem('theme') ? (localStorage.getItem('theme') as IThemeState['Theme']) : 'dark',
  SetTheme: (theme) => {
    localStorage.setItem('theme', theme);
    set((state) => ({ ...state, Theme: theme }));
  },
}));

export default useThemeState;
