import * as React from 'react';
import styled from 'styled-components';
import CardRow from '../../components/CardRow/CardRow';
import { ICardFormSubmission } from './models';
import CardForm from '../../components/CardForm/CardForm';
import { ICard, IDeck } from '../../state/decks';

const EditFormContainer = styled.div`
  padding: 50px;
  border-radius: 8px;
  margin: 20px 0;
  background: ${(props) => props.theme.colors.backgroundCard};

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    margin-left: 0;
  }
`;

interface ICardRowAndForm {
  card: ICard;
  deck: IDeck;
  onAudioClicked: (literal: string) => Promise<Blob>;
  onRomanizeClicked: (literal: string) => Promise<string>;
  onTranslateClicked: (translation: string) => Promise<string>;
  onEditCard: (id: string, data: ICardFormSubmission) => Promise<void>;
  onDeleteCard: (id: string) => Promise<void>;
  style?: React.CSSProperties;
}

const CardRowAndForm = (props: ICardRowAndForm) => {
  const { card, deck } = props;

  const [editing, setEditing] = React.useState<boolean>(false);

  const updateCard = async (id: string, data: ICardFormSubmission) => {
    setEditing(false);
    await props.onEditCard(id, data);
  };

  const deleteCard = async (id: string) => {
    setEditing(false);
    props.onDeleteCard(id);
  };

  return (
    <div style={props.style}>
      <div>
        <CardRow
          literal={card.literal}
          hasPronunciation={deck.hasPronunciation}
          pronunciation={card.pronunciation}
          translation={card.translation}
          notes={card.notes}
          streak={card.streak}
          tags={card.tags}
          onEditClicked={() => setEditing(!editing)}
          hasTTS={deck.hasTTS}
        />
        {editing && (
          <EditFormContainer>
            <CardForm
              hasPronunciation={deck.hasPronunciation}
              onSubmit={(data) => updateCard(card.id, data)}
              defaultValue={card}
              allowDeletion
              onDelete={() => deleteCard(card.id)}
              defaultLiteral={deck.defaultLiteral}
              defaultPronunciation={deck.defaultPronunciation}
              romanizationMethod={deck.romanizationMethod}
              hasTTS={deck.hasTTS}
              onAudioClicked={props.onAudioClicked}
              onRomanizeClicked={props.onRomanizeClicked}
              onTranslateClicked={props.onTranslateClicked}
            />
          </EditFormContainer>
        )}
      </div>
    </div>
  );
};

export default CardRowAndForm;
