import { IError } from '../pages/Settings/models';

export interface ITranslateResponse {
  literal: string;
}

export interface IRomanizeResponse {
  romanized: string;
}

const Translate = {
  Get: async (token: string, text: string, from: string, to: string) => {
    const resp = await fetch(`${import.meta.env.VITE_HOST}/translate`, {
      method: 'POST',
      body: JSON.stringify({
        text,
        from,
        to,
      }),
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    });

    const data: ITranslateResponse | IError = await resp.json();
    return data;
  },
  Romanize: async (token: string, text: string, from: string) => {
    const resp = await fetch(`${import.meta.env.VITE_HOST}/translate/romanize`, {
      method: 'POST',
      body: JSON.stringify({
        text,
        from,
      }),
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    });

    const data: IRomanizeResponse | IError = await resp.json();
    return data;
  },
};

export default Translate;
