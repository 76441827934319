import { ICardFormSubmission, IDeckUpdateSubmission } from '../pages/DeckDetails/models';
import { IAnkiDeckColumnForm } from '../pages/DeckSettings/Anki/models';
import { ITrainingCard } from '../pages/Training';
import { ICard, IDeck } from '../state/decks';
import { ILanguage } from '../state/languages';
import { IGetTagsResponse, IImportCardsResponse, INewDeckData } from '../types/decks';
import { TTrainingResponseDifficulty } from '../types/training';
import { Notify } from '../utils/errors';
import MockAPI, { IsMock } from '../utils/mock';

const Decks = {
  GetDecks: async (token: string): Promise<IDeck[]> => {
    if (IsMock()) {
      return MockAPI.GetDecks();
    }

    const resp = await fetch(`${import.meta.env.VITE_HOST}/decks`, {
      method: 'GET',
      headers: {
        Authorization: token,
      },
    });
    if (resp.status === 200) {
      const data: IDeck[] = await resp.json();
      return data;
    }

    return [];
  },
  GetCards: async (token: string, deck: string): Promise<ICard[]> => {
    if (IsMock()) {
      return MockAPI.GetCards(deck);
    }

    const resp = await fetch(`${import.meta.env.VITE_HOST}/decks/${deck}`, {
      method: 'GET',
      headers: {
        Authorization: token,
      },
    });
    if (resp.status === 200) {
      const data: ICard[] = await resp.json();
      return data;
    }

    return [];
  },
  DeleteDeck: async (token: string, deck: string) => {
    if (IsMock()) {
      return MockAPI.DeleteDeck(deck);
    }

    await fetch(`${import.meta.env.VITE_HOST}/decks/${deck}`, {
      method: 'DELETE',
      headers: {
        Authorization: token,
      },
    });
  },
  CreateDeck: async (token: string, data: INewDeckData, language: ILanguage): Promise<IDeck> => {
    if (IsMock()) {
      return MockAPI.CreateDeck(data, language);
    }

    const resp = await fetch(`${import.meta.env.VITE_HOST}/decks`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    });
    const respData: IDeck = await resp.json();
    return respData;
  },
  UpdateDeck: async (token: string, deck: string, data: IDeckUpdateSubmission): Promise<IDeck> => {
    if (IsMock()) {
      return MockAPI.UpdateDeck(deck, data);
    }

    const resp = await fetch(`${import.meta.env.VITE_HOST}/decks/${deck}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    });
    const respData: IDeck = await resp.json();
    return respData;
  },
  CreateCard: async (token: string, deck: string, data: ICardFormSubmission): Promise<ICard> => {
    if (IsMock()) {
      return MockAPI.CreateCard(deck, data);
    }

    const resp = await fetch(`${import.meta.env.VITE_HOST}/decks/${deck}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    });
    const respData: ICard = await resp.json();
    return respData;
  },
  UpdateCard: async (token: string, deck: string, card: string, data: ICardFormSubmission): Promise<ICard> => {
    if (IsMock()) {
      return MockAPI.UpdateCard(deck, card, data);
    }

    const resp = await fetch(`${import.meta.env.VITE_HOST}/decks/${deck}/${card}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    });
    const respData: ICard = await resp.json();
    return respData;
  },
  DeleteCard: async (token: string, deck: string, card: string): Promise<void> => {
    if (IsMock()) {
      return MockAPI.DeleteCard(deck, card);
    }

    await fetch(`${import.meta.env.VITE_HOST}/decks/${deck}/${card}`, {
      method: 'DELETE',
      headers: {
        Authorization: token,
      },
    });
  },
  GetTrainingCard: async (token: string, deck: string, params?: { tag?: string }): Promise<ITrainingCard> => {
    if (IsMock()) {
      return MockAPI.GetTrainingCard(deck);
    }

    const resp = await fetch(`${import.meta.env.VITE_HOST}/training/${deck}`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'X-Pomelo-Tag': params?.tag || '',
      },
    });

    const data: ITrainingCard = await resp.json();
    return data;
  },
  FinishTrainingCard: async (
    token: string,
    deck: string,
    card: string,
    difficulty: TTrainingResponseDifficulty,
    tag?: string
  ): Promise<boolean> => {
    if (IsMock()) {
      return MockAPI.FinishTrainingCard(deck, card, difficulty);
    }

    const resp = await fetch(`${import.meta.env.VITE_HOST}/training/${deck}/${card}`, {
      method: 'PATCH',
      body: JSON.stringify({ difficulty }),
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        'X-Pomelo-Tag': tag || '',
      },
    });

    if (resp.status !== 204) {
      Notify(await resp.text());
    }

    return resp.status === 204;
  },
  GetAnkiColumns: async (token: string, anki: File): Promise<string[]> => {
    if (IsMock()) {
      return ['Front', 'Back', 'Audio', 'Transcription'];
    }

    const fd = new FormData();
    fd.set('package', anki);

    const resp = await fetch(`${import.meta.env.VITE_HOST}/decks/anki/columns`, {
      method: 'POST',
      body: fd,
      headers: {
        Authorization: token,
      },
    });

    const arr: string[] = await resp.json();
    return arr;
  },
  UploadAnki: async (token: string, deck: string, anki: File, columns: IAnkiDeckColumnForm): Promise<void> => {
    if (IsMock()) {
      return;
    }

    const fd = new FormData();
    fd.set('package', anki);
    fd.set('body', JSON.stringify(columns));

    await fetch(`${import.meta.env.VITE_HOST}/decks/${deck}/anki`, {
      method: 'POST',
      body: fd,
      headers: {
        Authorization: token,
      },
    });
  },
  ImportCards: async (token: string, deck: string, cards: Partial<ICard>[]): Promise<IImportCardsResponse> => {
    if (IsMock()) {
      return MockAPI.ImportCards(deck, cards);
    }

    const resp = await fetch(`${import.meta.env.VITE_HOST}/decks/${deck}/import`, {
      method: 'POST',
      body: JSON.stringify({ cards }),
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    });

    const data = (await resp.json()) as IImportCardsResponse;

    return data;
  },
  GetTags: async (token: string): Promise<IGetTagsResponse> => {
    if (IsMock()) {
      return MockAPI.GetTags();
    }

    const resp = await fetch(`${import.meta.env.VITE_HOST}/decks/tags`, {
      method: 'GET',
      headers: {
        Authorization: token,
      },
    });

    const data = (await resp.json()) as IGetTagsResponse;

    return data;
  },
  SwapCardOrder: async (token: string, source: string, target: string) => {
    if (IsMock()) {
      return MockAPI.SwapCardOrder(source, target);
    }

    const resp = await fetch(`${import.meta.env.VITE_HOST}/decks/swap/${source}/${target}`, {
      method: 'PATCH',
      headers: {
        Authorization: token,
      },
    });

    return resp.status === 204;
  },
};

export default Decks;
