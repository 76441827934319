import styled from 'styled-components';

interface ICenterProps {
  verticalStretch?: boolean;
}

const Center = styled.div<ICenterProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  width: 100%;
  min-height: ${(props) => (props.verticalStretch ? '100%' : 'auto')};
`;

export default Center;
