import { ILanguage } from '../state/languages';
import { IsMock } from '../utils/mock';

const Languages = {
  Get: async (): Promise<ILanguage[]> => {
    try {
      const resp = await fetch(`${import.meta.env.VITE_HOST}/languages`, {
        method: 'GET',
      });
      const langs: ILanguage[] = await resp.json();
      return langs;
    } catch (err) {
      if (IsMock()) {
        return [
          {
            id: '1111',
            name: 'Mandarin (Simplified)',
            flag: '',
            iso_2: 'cn',
            iso_3: 'chn',
            hasPronunciation: true,
            locale: 'zh-Hans-CN',
            defaultLiteral: '你好',
            defaultPronunciation: 'nǐhǎo',
            romanizationMethod: '',
            hasTTS: true,
          },
          {
            id: '2222',
            flag: '',
            name: 'Spanish (Mexico)',
            iso_2: 'mx',
            iso_3: 'mex',
            hasPronunciation: false,
            locale: 'es-MX',
            defaultLiteral: 'hola',
            defaultPronunciation: '',
            romanizationMethod: '',
            hasTTS: true,
          },
          {
            id: '3333',
            flag: '',
            name: 'Arabic (MSA)',
            iso_2: 'eg',
            iso_3: 'egy',
            hasPronunciation: true,
            locale: 'ar-001',
            defaultLiteral: 'أهلا',
            defaultPronunciation: 'ahlan',
            romanizationMethod: '',
            hasTTS: true,
          },
          {
            id: '4444',
            flag: '',
            name: 'Hindi',
            iso_2: 'in',
            iso_3: 'ind',
            hasPronunciation: true,
            locale: 'hi-IN',
            defaultLiteral: 'नमस्ते',
            defaultPronunciation: 'namaste',
            romanizationMethod: '',
            hasTTS: true,
          },
        ];
      }

      throw err;
    }
  },
};

export default Languages;
