import * as React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import Button from '../../components/Button';
import FormError from '../../components/FormError';
import Header from '../../components/Header';
import InputRow from '../../components/InputRow';
import Label from '../../components/Label';
import Select, { Option } from '../../components/Select';
import TextInput from '../../components/TextInput';
import { ILanguage } from '../../state/languages';
import { INewDeckFormData } from './models';

const Instruction = styled.p`
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.foregroundDim};
`;

const NameValidators = {
  required: { value: true, message: 'Name is required.' },
};

const LanguageValidators = {
  required: { value: true, message: 'Language is required.' },
};

interface INewDeckProps {
  languages: ILanguage[];
  onSubmit: (data: INewDeckFormData) => Promise<void>;
}

const NewDeck: React.FC<INewDeckProps> = (props) => {
  const form = useForm<INewDeckFormData>();

  const create = form.handleSubmit(async (data) => {
    await props.onSubmit(data);
  });

  return (
    <>
      <Header size="lg">New Deck</Header>
      <form onSubmit={create}>
        <InputRow>
          <Label>Name</Label>
          <Instruction>This can be anything. We usually use the language name.</Instruction>
          <TextInput placeholder="My Deck" {...form.register('name', NameValidators)} />
          {form.formState.errors?.name && <FormError>{form.formState.errors?.name.message}</FormError>}
        </InputRow>
        <InputRow>
          <Label>Language</Label>
          <Instruction>This will affect text-to-speech, pronunciation, auto-translation, etc.</Instruction>
          <Select placeholder="Language Name" {...form.register('baseLanguageId', LanguageValidators)} defaultValue="">
            <Option value="" disabled>
              Select
            </Option>
            {props.languages
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((lang) => (
                <Option key={lang.id} value={lang.id}>
                  {lang.name}
                </Option>
              ))}
          </Select>
          {form.formState.errors?.baseLanguageId && <FormError>{form.formState.errors?.baseLanguageId.message}</FormError>}
        </InputRow>
        <Button>Create</Button>
      </form>
    </>
  );
};

export default NewDeck;
