import * as React from 'react';
import useAuthState from '../../../state/auth';
import { useNavigate } from 'react-router-dom';
import Stateless from './index.stateless';

const Home: React.FC = () => {
  const auth = useAuthState();
  const navigate = useNavigate();

  const openMock = () => {
    localStorage.setItem('mock', '1');
    navigate('/dashboard');
  };

  const openDashboard = () => navigate('/dashboard');
  const openLogin = () => navigate('/login');
  const openRegister = () => navigate('/register');

  return (
    <Stateless
      isLoggedIn={!!auth.User}
      openMock={openMock}
      openDashboard={openDashboard}
      openLogin={openLogin}
      openRegister={openRegister}
    />
  );
};

export default Home;
