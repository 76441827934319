import { ITag } from '../../types/tag';

export interface ICardForm {
  literal: string;
  pronunciation: string;
  translation: string;
  notes: string;
}

export interface ICardFormSubmission extends ICardForm {
  tags: ITag[];
}

export const LiteralValidators = {
  required: { value: true, message: 'Literal is required.' },
};

export const TranslationValidators = {
  required: { value: true, message: 'Translation is required.' },
};

export const NotesValidators = {};

export interface IDeckUpdateSubmission {
  name: string;
}
