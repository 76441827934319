import * as React from 'react';
import Auth from '../../api/auth';
import FeedbackModalStateless from '../../components/FeedbackModal/FeedbackModalStateless';
import useAuthState from '../../state/auth';

interface IFeedbackModalProps {
  onSubmit: () => void;
}

const FeedbackModal: React.FC<IFeedbackModalProps> = (props) => {
  const authState = useAuthState();

  const send = async (message: string) => {
    if (message) {
      await Auth.Feedback({ message }, authState.User?.token);
    }
    props.onSubmit();
  };

  return <FeedbackModalStateless onSubmit={send} />;
};

export default FeedbackModal;
