import styled from 'styled-components';

interface ILabelProps {
  inline?: boolean;
}

const Label = styled.label<ILabelProps>`
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};
  margin-bottom: ${(props) => (props.inline ? 0 : 2)}px;
  margin-left: ${(props) => (props.inline ? 2 : 0)}px;
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.foregroundDim};
`;

export default Label;
