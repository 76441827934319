import * as React from 'react';
import styled, { css } from 'styled-components';

interface IHProps extends React.HTMLProps<HTMLHeadingElement> {
  nomargin?: boolean;
  clickable?: boolean;
}

const HeadingProps = css<IHProps>`
  margin: 0;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'inherit')};
  font-family: ${(props) => props.theme.fonts.header};
  font-weight: 300;
`;

const H1 = styled.h1<IHProps>`
  ${HeadingProps}
  margin-bottom: ${(props) => (props.nomargin ? '0' : '50px')};
  font-size: 2em;
`;

const H2 = styled.h2<IHProps>`
  ${HeadingProps}
  margin-bottom: ${(props) => (props.nomargin ? '0' : '50px')};
`;

const H3 = styled.h3<IHProps>`
  ${HeadingProps}
  margin-bottom: ${(props) => (props.nomargin ? '0' : '10px')};
`;

interface IHeaderProps extends React.PropsWithChildren {
  size: 'sm' | 'md' | 'lg';
  nomargin?: boolean;
  onClick?: () => void;
}

const Header: React.FC<IHeaderProps> = (props) => {
  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLHeadingElement>) => {
      event.preventDefault();
      if (props.onClick) {
        props.onClick();
      }
    },
    [props.onClick]
  );

  switch (props.size) {
    case 'lg':
    default:
      return (
        <H1 nomargin={props.nomargin} clickable={!!props.onClick} onClick={onClick}>
          {props.children}
        </H1>
      );
    case 'md':
      return (
        <H2 nomargin={props.nomargin} clickable={!!props.onClick} onClick={onClick}>
          {props.children}
        </H2>
      );
    case 'sm':
      return (
        <H3 nomargin={props.nomargin} clickable={!!props.onClick} onClick={onClick}>
          {props.children}
        </H3>
      );
  }
};

export default Header;
