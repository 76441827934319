import * as React from 'react';
import useAuthState from '../../state/auth';
import Auth from '../../api/auth';
import { useNavigate } from 'react-router-dom';
import Stateless from './index.stateless';
import { IRegisterFormData, IRegisterResponse } from './models';
import { Notify } from '../../utils/errors';

interface IRegisterProps {}

const Register: React.FC<IRegisterProps> = (props) => {
  const authState = useAuthState();
  const navigate = useNavigate();

  const onRegister = async (data: IRegisterFormData) => {
    try {
      const respData = (await Auth.Register(data)) as IRegisterResponse;

      plausible('Register/Complete');

      authState.SetUser({
        country: respData.country,
        email: respData.email,
        name: respData.name,
        token: respData.token,
        postalcode: respData.postalcode,
        stripeSubscriptionStatus: respData.stripeSubscriptionStatus,
      });
      localStorage.removeItem('mock');
      navigate(`/dashboard`);
    } catch (err: any) {
      Notify(err);
    }
  };

  React.useEffect(() => {
    plausible('Register/View');
  }, []);

  const navigateLogin = () => navigate('/login');

  return <Stateless onRegister={onRegister} navigateLogin={navigateLogin} />;
};

export default Register;
