import * as React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { IUpdateBody, IUpdatePasswordBody } from './models';
import Button from '../../components/Button';
import FormError from '../../components/FormError';
import Header from '../../components/Header';
import InputRow from '../../components/InputRow';
import Label from '../../components/Label';
import Select, { Option } from '../../components/Select';
import TextInput from '../../components/TextInput';
import Countries from '../../utils/countries';
import { CountryValidators, EmailValidators, NameValidators, PasswordValidators } from '../../utils/validators';
import { IUser } from '../../state/auth';

const DetailsForm = styled.div`
  margin-bottom: 30px;
  max-width: 400px;
  background: ${(props) => props.theme.colors.backgroundCard};
  border: 1px solid ${(props) => props.theme.colors.border};
  padding: 50px;
  border-radius: 4px;

  @media screen and (max-width: ${(props) => props.theme.responsive.phone}px) {
    padding: 20px;
  }
`;

const SettingsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 30px;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    grid-template-columns: 1fr;
  }
`;

interface ISettingsProps {
  user: IUser | undefined;
  update: (data: IUpdateBody) => Promise<void>;
  updatePassword: (data: IUpdatePasswordBody) => Promise<void>;
}

const Settings = (props: ISettingsProps) => {
  /*
  const [loadingManage, setLoadingManage] = React.useState(false);

  const manageBilling = React.useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setLoadingManage(true);
    try {
      const { url } = await Payment.Manage(authState.User.token);
      window.location.assign(url);
    } catch (err) {}
    setLoadingManage(false);
  }, []);*/

  const [loading, setLoading] = React.useState(false);

  const form = useForm<IUpdateBody>({
    defaultValues: props.user
      ? {
          country: props.user.country,
          email: props.user.email,
          name: props.user.name,
          postalcode: props.user.postalcode,
        }
      : {},
  });
  const country = form.watch('country');

  React.useEffect(() => {
    if (props.user) {
      form.reset({
        country: props.user.country,
        email: props.user.email,
        name: props.user.name,
        postalcode: props.user.postalcode,
      });
    }
  }, [props.user]);

  const update = form.handleSubmit(async (data) => {
    setLoading(true);

    try {
      const promise = props.update(data);

      toast.promise(promise, {
        loading: 'Updating...',
        success: 'Updated.',
        error: 'Error updating.',
      });

      await promise;
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  });

  const passwordForm = useForm<IUpdatePasswordBody>();

  const updatePassword = passwordForm.handleSubmit(async (data) => {
    setLoading(true);

    try {
      const promise = props.updatePassword(data);

      toast.promise(promise, {
        loading: 'Updating...',
        success: 'Updated.',
        error: 'Error updating.',
      });

      await promise;
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  });

  return (
    <>
      <Header size="lg">Settings</Header>
      <SettingsContainer>
        <DetailsForm>
          <Header size="md">Profile</Header>
          <form onSubmit={update}>
            <InputRow>
              <Label>Name</Label>
              <TextInput block placeholder="First Last" autoComplete="name" {...form.register('name', NameValidators)} />
              {form.formState.errors?.name && <FormError>{form.formState.errors?.name.message}</FormError>}
            </InputRow>
            <InputRow>
              <Label>Email</Label>
              <TextInput block placeholder="name@email.com" autoComplete="email" {...form.register('email', EmailValidators)} />
              {form.formState.errors?.email && <FormError>{form.formState.errors?.email.message}</FormError>}
            </InputRow>
            <InputRow>
              <Label>Country</Label>
              <Select block {...form.register('country', CountryValidators)} defaultValue="" autoComplete="country">
                <Option value="" disabled>
                  Select
                </Option>
                {Countries.map((v) => (
                  <Option key={v.abbreviation} value={v.abbreviation.toLowerCase()}>
                    {v.country}
                  </Option>
                ))}
              </Select>
              {form.formState.errors?.country && <FormError>{form.formState.errors?.country.message}</FormError>}
            </InputRow>
            <InputRow>
              <Label>{country === 'us' ? 'ZIP' : 'Postal Code'}</Label>
              <TextInput
                block
                placeholder={country === 'us' ? '90210' : 'N6H 5H2'}
                autoComplete="postal-code"
                {...form.register('postalcode', {
                  required: { value: true, message: `${country === 'us' ? 'ZIP' : 'Postal code'} is required.` },
                })}
              />
              {form.formState.errors?.postalcode && <FormError>{form.formState.errors?.postalcode.message}</FormError>}
            </InputRow>
            <Button disabled={loading}>Update</Button>
          </form>
        </DetailsForm>
        <DetailsForm>
          <Header size="md">Password</Header>
          <form onSubmit={updatePassword}>
            <InputRow>
              <Label>Old Password</Label>
              <TextInput
                block
                placeholder="p@s5w0rd"
                type="password"
                autoComplete="current-password"
                {...passwordForm.register('oldPassword', PasswordValidators)}
              />
              {passwordForm.formState.errors?.oldPassword && <FormError>{passwordForm.formState.errors?.oldPassword.message}</FormError>}
            </InputRow>
            <InputRow>
              <Label>New Password</Label>
              <TextInput
                block
                placeholder="p@s5w0rd"
                type="password"
                autoComplete="new-password"
                {...passwordForm.register('newPassword', PasswordValidators)}
              />
              {passwordForm.formState.errors?.newPassword && <FormError>{passwordForm.formState.errors?.newPassword.message}</FormError>}
            </InputRow>
            <Button disabled={loading}>Update</Button>
          </form>
        </DetailsForm>
      </SettingsContainer>
      {/*
      <Header size="md">Billing</Header>
      {authState.User?.stripeSubscriptionStatus === 'comped' ? (
        <>You are comped membership and so have no billing!</>
      ) : (
        <div>
          {loadingManage ? (
            <Loader />
          ) : (
            <Button onClick={manageBilling} disabled={IsMock()}>
              {IsMock() ? 'Manage Billing (you are in demo mode)' : 'Manage Billing'}
            </Button>
          )}
        </div>
          )}*/}
    </>
  );
};

export default Settings;
