import * as React from 'react';
import Auth from '../../api/auth';
import useAuthState from '../../state/auth';
import Stateless from './index.stateless';
import { IUpdateBody, IUpdatePasswordBody } from './models';

const Settings: React.FC = () => {
  const authState = useAuthState();

  const update = async (data: IUpdateBody) => {
    plausible('User/Update');

    await Auth.Update(data, authState.User?.token || '');

    authState.SetUser({
      ...authState.User!,
      country: data.country,
      email: data.email,
      name: data.name,
      postalcode: data.postalcode,
    });
  };

  const updatePassword = async (data: IUpdatePasswordBody) => {
    plausible('User/Update Password');
    await Auth.UpdatePassword(data, authState.User?.token || '');
  };

  return <Stateless user={authState.User} update={update} updatePassword={updatePassword} />;
};

export default Settings;
