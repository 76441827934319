import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styled from 'styled-components';
import { CardButton } from '../../components/Card';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import { IDeck } from '../../state/decks';
import Deck from '../../components/Deck/Deck';

const DecksContainer = styled.div`
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 1fr 1fr;

  @media screen and (max-width: ${(props) => props.theme.responsive.phoneLandscape}px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

const CreateNewLabel = styled.div`
  font-size: 1.4em;
  font-family: ${(props) => props.theme.fonts.header};
  font-style: italic;
  color: ${(props) => props.theme.colors.foregroundDim};
`;

const CreateNewIcon = styled.div`
  font-size: 1.5em;
  margin-top: 10px;
  color: ${(props) => props.theme.colors.foregroundDim};
`;

interface IDeckListProps {
  decks: IDeck[];
  loading: boolean;
  onSelectDeck: (id: string) => void;
  onNewDeck: () => void;
  decksError: string;
}

const DeckList = (props: IDeckListProps) => {
  return (
    <>
      <Header size="lg">Decks</Header>
      {props.loading ? (
        <Loader />
      ) : props.decksError ? (
        <p>{props.decksError}</p>
      ) : (
        <DecksContainer>
          {props.decks.map((deck) => (
            <Deck key={deck.id} name={deck.name} language={deck.language} flag={deck.iso_2} onClick={() => props.onSelectDeck(deck.id)} />
          ))}
          <CardButton type="button" onClick={() => props.onNewDeck()}>
            <CreateNewIcon>
              <FontAwesomeIcon icon={faArrowRight} />
            </CreateNewIcon>
            <div>
              <CreateNewLabel>Create New...</CreateNewLabel>
            </div>
          </CardButton>
          {props.decks.length === 0 && (
            <div>
              <p>Create your first deck to get started.</p>
              <p>A deck is a list of words.</p>
              <p>Once you do some initial setup, you can start practicing your language.</p>
            </div>
          )}
        </DecksContainer>
      )}
    </>
  );
};

export default DeckList;
