import { faCheck } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import Card from '../../components/Card';
import Center from '../../components/Center';
import FormError from '../../components/FormError';
import Header from '../../components/Header';
import IconButton from '../../components/IconButton';
import InputRow from '../../components/InputRow';
import Label from '../../components/Label';
import Loader from '../../components/Loader';
import PageContainer from '../../components/PageContainer';
import TextInput from '../../components/TextInput';
import { EmailValidators, PasswordValidators } from '../../utils/validators';

export const RegisterLink = styled.a`
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.foregroundDim};
  margin-top: 20px;
  text-align: left;
  display: block;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }
`;

interface ILoginFormData {
  email: string;
  password: string;
}

interface ILoginProps {
  onSubmit: (user: ILoginFormData) => Promise<void>;
  navigateRegister: () => void;
}

const Login: React.FC<ILoginProps> = (props) => {
  const form = useForm<ILoginFormData>();

  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const login = form.handleSubmit(async (data) => {
    setError('');
    setLoading(true);
    try {
      await props.onSubmit(data);
    } catch (err: any) {
      setLoading(false);
      if (err && err.error === 'invalid_auth') {
        setError(err.error);
      } else {
        setError('error');
      }
    }
  });

  return (
    <PageContainer>
      <Center verticalStretch>
        <Card noBorder margin fullWidthOnMobile>
          <form onSubmit={login}>
            <Header size="lg">Login</Header>
            <InputRow>
              <Label>Email</Label>
              <TextInput block placeholder="name@email.com" autoComplete="email" {...form.register('email', EmailValidators)} />
              {form.formState.errors?.email && <FormError>{form.formState.errors?.email.message}</FormError>}
            </InputRow>
            <InputRow>
              <Label>Password</Label>
              <TextInput
                block
                placeholder="p@s5w0rd"
                type="password"
                autoComplete="current-password"
                {...form.register('password', PasswordValidators)}
              />
              {form.formState.errors?.password && <FormError>{form.formState.errors?.password.message}</FormError>}
              {error === 'invalid_auth' && <FormError>Invalid email or password.</FormError>}
              {error === 'error' && <FormError>There was an error logging in. Please try again later.</FormError>}
            </InputRow>
            {loading ? <Loader /> : <IconButton onClick={login} icon={faCheck} />}
          </form>
          <RegisterLink onClick={props.navigateRegister}>No account? Register here</RegisterLink>
        </Card>
      </Center>
    </PageContainer>
  );
};

export default Login;
