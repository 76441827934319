import * as React from 'react';
import { useForm } from 'react-hook-form';
import PageContainer from '../../components/PageContainer';
import Center from '../../components/Center';
import Card from '../../components/Card';
import Header from '../../components/Header';
import { RegisterLink } from '../Login/index.stateless';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../components/Loader';
import IconButton from '../../components/IconButton';
import InputRow from '../../components/InputRow';
import Label from '../../components/Label';
import { EmailValidators, NameValidators, PasswordValidators } from '../../utils/validators';
import FormError from '../../components/FormError';
import TextInput from '../../components/TextInput';
import { IRegisterFormData } from './models';

interface IRegisterProps {
  onRegister: (data: IRegisterFormData) => Promise<void>;
  navigateLogin: () => void;
}

const Register: React.FC<IRegisterProps> = (props) => {
  const form = useForm<IRegisterFormData>();

  const [loading, setLoading] = React.useState(false);

  const submitDetails = form.handleSubmit(async (data) => {
    setLoading(true);
    await props.onRegister(data);
    setLoading(false);
  });

  return (
    <PageContainer>
      <Center verticalStretch>
        <Card noBorder margin fullWidthOnMobile>
          <Header size="lg">Register</Header>
          <form
            onSubmit={submitDetails}
            style={{ display: 'flex', justifyContent: 'stretch', flexDirection: 'column', flexWrap: 'nowrap' }}
          >
            <>
              <InputRow>
                <Label>Name</Label>
                <TextInput block placeholder="First Last" autoComplete="name" {...form.register('name', NameValidators)} />
                {form.formState.errors?.name && <FormError>{form.formState.errors?.name.message}</FormError>}
              </InputRow>
              <InputRow>
                <Label>Email</Label>
                <TextInput block placeholder="name@email.com" autoComplete="email" {...form.register('email', EmailValidators)} />
                {form.formState.errors?.email && <FormError>{form.formState.errors?.email.message}</FormError>}
              </InputRow>
              <InputRow>
                <Label>Password</Label>
                <TextInput
                  block
                  placeholder="p@s5w0rd"
                  type="password"
                  autoComplete="new-password"
                  {...form.register('password', PasswordValidators)}
                />
                {form.formState.errors?.password && <FormError>{form.formState.errors?.password.message}</FormError>}
              </InputRow>
            </>
            {loading ? <Loader /> : <IconButton type="submit" icon={faCheck} />}
            <RegisterLink onClick={props.navigateLogin}>Already have an account?</RegisterLink>
          </form>
        </Card>
      </Center>
    </PageContainer>
  );
};

export default Register;
