import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import Header from '../Header';
import IconButton from '../IconButton';
import Modal from '../Modal';
import SpaceBetween from '../SpaceBetween';

const ModalParagraph = styled.p`
  margin-bottom: 30px;
`;

interface IConfirmModalProps {
  title: string;
  message: string;
  onConfirm: () => void;
  onReject: () => void;
}

const ConfirmModal: React.FC<IConfirmModalProps> = (props) => {
  const onConfirm = React.useCallback(() => {
    props.onConfirm();
  }, [props.onConfirm]);
  const onReject = React.useCallback(() => {
    props.onReject();
  }, [props.onReject]);

  return (
    <Modal onClickOutside={onReject}>
      <Header size="lg" nomargin>
        {props.title}
      </Header>
      <ModalParagraph>{props.message}</ModalParagraph>
      <SpaceBetween>
        <IconButton type="button" icon={faCheck} big onClick={onConfirm} />
      </SpaceBetween>
    </Modal>
  );
};

export default ConfirmModal;
