import * as React from 'react';
import styled from 'styled-components';
import useOutsideAlerter from '../../utils/useClickOutside';
import { CardCSS } from '../Card';

const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  z-index: 11;
`;

const ModalPanel = styled.div`
  ${CardCSS};

  max-width: 500px;
  margin: 0 20px;

  @media screen and (max-width: ${(props) => props.theme.responsive.phone}px) {
    width: 90%;
    max-width: 90%;
    box-sizing: border-box;
  }
`;

interface ModalProps extends React.PropsWithChildren {
  onClickOutside: () => void;
}

const Modal: React.FC<ModalProps> = (props) => {
  const ref = React.createRef<HTMLDivElement>();
  useOutsideAlerter(
    ref,
    () => {
      props.onClickOutside();
    },
    [props.onClickOutside]
  );

  return (
    <ModalContainer>
      <ModalPanel ref={ref}>{props.children}</ModalPanel>
    </ModalContainer>
  );
};

export default Modal;
