import styled from 'styled-components';
import { IInputProps, InputBase } from '../TextInput';

const Select = styled.select<IInputProps>`
  ${InputBase}
`;

export default Select;

export const Option = styled.option`
  padding: 8px;
`;
