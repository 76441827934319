import { ICardFormSubmission, IDeckUpdateSubmission } from '../pages/DeckDetails/models';
import { IUpdateBody, IUpdatePasswordBody } from '../pages/Settings/models';
import { ITrainingCard } from '../pages/Training';
import { IUser } from '../state/auth';
import { ICard, IDeck } from '../state/decks';
import { ILanguage } from '../state/languages';
import { IGetTagsResponse, INewDeckData } from '../types/decks';
import { ITag } from '../types/tag';
import { TTrainingResponseDifficulty } from '../types/training';

export const IsMock = (): boolean => {
  return !!localStorage.getItem('mock');
};

const monthToHuman = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const MockTags: ITag[] = [
  { id: '0', value: 'Lesson - June 28' },
  { id: '1', value: `Food` },
  { id: '2', value: `Essentials` },
];

export const MockDecks: IDeck[] = [
  {
    id: '0',
    name: 'Chinese Practice',
    language: 'Mandarin',
    iso_2: 'cn',
    hasPronunciation: true,
    locale: 'zh-CN',
    defaultLiteral: '你好',
    defaultPronunciation: 'nǐhǎo',
    romanizationMethod: '',
    hasTTS: true,
  },
  {
    id: '1',
    name: 'Spanish Phrases',
    language: 'Spanish',
    iso_2: 'es',
    hasPronunciation: false,
    locale: 'es-MX',
    defaultLiteral: 'hola',
    defaultPronunciation: '',
    romanizationMethod: '',
    hasTTS: true,
  },
  {
    id: '2',
    name: 'Thai Travel',
    language: 'Thai',
    iso_2: 'th',
    hasPronunciation: true,
    locale: 'th-TH',
    defaultLiteral: 'สวัสดี',
    defaultPronunciation: 'sawasdii',
    romanizationMethod: '',
    hasTTS: true,
  },
];

export const MockCards: { [deckId: string]: ICard[] } = {
  '0': [
    {
      id: '0',
      literal: '你好',
      pronunciation: 'nǐhǎo',
      translation: 'hello',
      next_showing: new Date().toISOString(),
      successes: 4,
      failures: 1,
      notes: 'Some notes here.',
      streak: 1,
      easiness: Math.random() * 4,
      tags: [],
    },
    {
      id: '1',
      literal: '叫',
      pronunciation: 'jiao4',
      translation: 'to be called',
      next_showing: new Date().toISOString(),
      successes: 5,
      failures: 0,
      notes: 'Specifically, the verb',
      streak: 5,
      easiness: Math.random() * 4,
      tags: [],
    },
    {
      id: '2',
      literal: '我',
      pronunciation: 'wo3',
      translation: 'me, I',
      next_showing: new Date().toISOString(),
      successes: 5,
      failures: 0,
      notes: '',
      streak: 4,
      easiness: Math.random() * 4,
      tags: [],
    },
  ],
  '1': [
    {
      id: '3',
      literal: 'yo hablo Español',
      pronunciation: '',
      translation: 'I speak Spanish',
      next_showing: new Date().toISOString(),
      successes: 4,
      failures: 1,
      notes: 'This phrase is present continuous',
      streak: 3,
      easiness: Math.random() * 4,
      tags: [],
    },
    {
      id: '4',
      literal: '¿donde está la biblioteca?',
      pronunciation: '',
      translation: 'where is the library?',
      next_showing: new Date().toISOString(),
      successes: 5,
      failures: 0,
      notes: 'Biblioteca is feminine',
      streak: 0,
      easiness: Math.random() * 4,
      tags: [],
    },
    {
      id: '5',
      literal: '¿cómo te llamas?',
      pronunciation: '',
      translation: 'what is your name?',
      next_showing: new Date().toISOString(),
      successes: 5,
      failures: 0,
      notes: '',
      streak: 5,
      easiness: Math.random() * 4,
      tags: [],
    },
  ],
  '2': [
    {
      id: '6',
      literal: 'ภาษาไทย',
      pronunciation: 'paa(H) saa tai(F)',
      translation: 'Thai language',
      next_showing: new Date().toISOString(),
      successes: 4,
      failures: 1,
      notes: '',
      streak: 2,
      easiness: Math.random() * 4,
      tags: [],
    },
    {
      id: '7',
      literal: 'ชายหาด',
      pronunciation: 'chai(H) haad',
      translation: 'beach',
      next_showing: new Date().toISOString(),
      successes: 5,
      failures: 0,
      notes: 'Can also use the short form, หาด (haad)',
      streak: 5,
      easiness: Math.random() * 4,
      tags: [],
    },
    {
      id: '8',
      literal: 'เบียร์',
      pronunciation: 'bia(F)',
      translation: 'beer',
      next_showing: new Date().toISOString(),
      successes: 5,
      failures: 0,
      notes: '"Bia" careful not to drink too much!',
      streak: 5,
      easiness: Math.random() * 4,
      tags: [],
    },
  ],
};

export const MockUser: IUser = {
  name: 'Demo User',
  email: 'ssmith@gmail.com',
  token: 'abcd1234',
  country: 'us',
  postalcode: '90210',
  stripeSubscriptionStatus: 'active',
};

export const MockLanguages: ILanguage[] = [
  {
    id: '1',
    name: 'Spanish (Mexico)',
    iso_2: 'mx',
    iso_3: 'mex',
    hasPronunciation: false,
    locale: 'es-MX',
    defaultLiteral: 'hola',
    flag: 'es',
    defaultPronunciation: '',
    romanizationMethod: '',
    hasTTS: true,
  },
  {
    id: '2',
    name: 'Mandarin (Simplified)',
    iso_2: 'cn',
    iso_3: 'chn',
    hasPronunciation: true,
    locale: 'zh-Hans-CN',
    defaultLiteral: '你好',
    defaultPronunciation: 'nǐhǎo',
    flag: 'zh',
    romanizationMethod: '',
    hasTTS: true,
  },
  {
    id: '3',
    name: 'French',
    iso_2: 'fr',
    iso_3: 'fra',
    hasPronunciation: false,
    locale: 'fr-FR',
    defaultLiteral: 'bonjour',
    defaultPronunciation: '',
    flag: 'fr',
    romanizationMethod: '',
    hasTTS: true,
  },
];

const MockAPI = {
  GetMe: (): IUser => {
    return MockUser;
  },
  GetDecks: async (): Promise<IDeck[]> => JSON.parse(JSON.stringify(MockDecks)),
  GetCards: async (deckId: string): Promise<ICard[]> => JSON.parse(JSON.stringify(MockCards[deckId])),
  DeleteDeck: async (deck: string): Promise<void> => {
    const loc = MockDecks.findIndex((v) => v.id === deck);
    MockDecks.splice(loc, 1);
  },
  CreateDeck: async (data: INewDeckData, language: ILanguage): Promise<IDeck> => {
    const id = Math.random().toString();
    const idx = MockDecks.push({
      id,
      name: data.name,
      language: language.name,
      iso_2: language.iso_2,
      hasPronunciation: language.hasPronunciation,
      locale: language.locale,
      defaultLiteral: language.defaultLiteral,
      defaultPronunciation: language.defaultPronunciation,
      romanizationMethod: language.romanizationMethod,
      hasTTS: language.hasTTS,
    });
    MockCards[id] = [];
    return MockDecks[idx - 1];
  },
  UpdateDeck: async (deck: string, data: IDeckUpdateSubmission): Promise<IDeck> => {
    const loc = MockDecks.findIndex((v) => v.id === deck);
    MockDecks[loc].name = data.name;
    return MockDecks[loc];
  },
  CreateCard: async (deck: string, data: ICardFormSubmission): Promise<ICard> => {
    const id = Math.random().toString();
    const card = {
      ...data,
      id,
      next_showing: new Date().toISOString(),
      successes: 0,
      failures: 0,
      notes: '',
      streak: 0,
      easiness: 2.5,
    };

    MockCards[deck].push(card);

    return card;
  },
  UpdateCard: async (deck: string, card: string, data: ICardFormSubmission): Promise<ICard> => {
    const idx = MockCards[deck].findIndex((v) => v.id === card);
    MockCards[deck][idx] = {
      ...MockCards[deck][idx],
      ...data,
    };
    return MockCards[deck][idx];
  },
  DeleteCard: async (deck: string, card: string): Promise<void> => {
    const idx = MockCards[deck].findIndex((v) => v.id === card);
    delete MockCards[deck][idx];
  },
  GetTrainingCard: async (deck: string): Promise<ITrainingCard> => {
    const cards = MockCards[deck];
    const idx = Math.floor(Math.random() * cards.length);
    const reverse = Math.random() < 0.5;
    const r = {
      cardId: MockCards[deck][idx].id,
      front: MockCards[deck][idx].literal,
      frontSub: MockCards[deck][idx].pronunciation,
      back: MockCards[deck][idx].translation,
      backSub: MockCards[deck][idx].pronunciation,
      notes: 'Maybe a mnemonic here, or some contextual info.',
      literal: MockCards[deck][idx].literal,
      literalOnFront: true,
      streak: Math.floor(Math.random() * 10),
      easiness: Math.random() * 4,
      hasTTS: true,
    };

    if (reverse) {
      r.literalOnFront = false;
      r.front = MockCards[deck][idx].translation;
      r.frontSub = '';
      r.back = MockCards[deck][idx].literal;
      r.backSub = MockCards[deck][idx].pronunciation;
    }

    return r;
  },
  FinishTrainingCard: async (deck: string, card: string, difficulty: TTrainingResponseDifficulty) => {
    return true;
  },
  Update: async (data: IUpdateBody) => {
    MockUser.country = data.country;
    MockUser.email = data.email;
    MockUser.name = data.name;
    MockUser.postalcode = data.postalcode;
  },
  UpdatePassword: async (data: IUpdatePasswordBody) => {},
  ImportCards: async (deck: string, cards: Partial<ICard>[]) => {
    return { new: Math.floor(Math.random() * 30), updated: Math.floor(Math.random() * 100) };
  },
  GetTags: async (): Promise<IGetTagsResponse> => {
    const now = new Date();
    const lastWeek = now;
    lastWeek.setDate(lastWeek.getDate() - 7);

    const usedTags = new Set<string>();
    const tags = new Set<ITag>();
    for (const deck in MockCards) {
      for (const card of MockCards[deck]) {
        for (const tag of card.tags) {
          if (!usedTags.has(tag.id + tag.value)) {
            usedTags.add(tag.id + tag.value);
            tags.add(tag);
          }
        }
      }
    }

    return {
      tags: Array.from(tags),
    };
  },
  SwapCardOrder: async (source: string, target: string): Promise<boolean> => {
    return true;
  },
};

export default MockAPI;
