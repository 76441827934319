import * as React from 'react';
import { CircleFlag } from 'react-circle-flags';
import styled from 'styled-components';
import { CardButton } from '../Card';
import Header from '../Header';

const DeckLanguage = styled.p`
  margin: 0;
  font-style: italic;
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.foregroundDim};
`;

const FlagContainer = styled.div`
  margin-bottom: 20px;
  height: 50px;
`;

const TextContainer = styled.div``;

interface IDeckProps {
  name: string;
  language: string;
  flag: string;
  onClick: () => void;
}

const Deck: React.FC<IDeckProps> = (props) => {
  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      props.onClick();
    },
    [props.onClick]
  );

  return (
    <CardButton onClick={onClick}>
      <FlagContainer>
        <CircleFlag countryCode={props.flag} height="100%" />
      </FlagContainer>
      <TextContainer>
        <Header size="sm">{props.name}</Header>
        <DeckLanguage>{props.language}</DeckLanguage>
      </TextContainer>
    </CardButton>
  );
};

export default Deck;
