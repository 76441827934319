import * as React from 'react';

const useOnline = (): boolean => {
  const [online, setOnline] = React.useState(window.navigator.onLine);

  React.useEffect(() => {
    const set = () => setOnline(window.navigator.onLine);

    window.addEventListener('online', set);
    window.addEventListener('offline', set);

    return () => {
      window.removeEventListener('online', set);
      window.removeEventListener('offline', set);
    };
  }, []);

  return online;
};

export default useOnline;
