import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
`;

type TPosition = 'top' | 'bottom' | 'left' | 'right';

interface ITooltipBubbleProps {
  position?: TPosition;
  offsets: [number, number, number, number];
}

const TooltipBubble = styled.div<ITooltipBubbleProps>`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -40px;
  white-space: nowrap;

  padding: 8px 12px;
  color: ${(props) => props.theme.colors.foreground};
  background-color: ${(props) => props.theme.colors.backgroundCard};
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;
  font-size: 0.8em;
  pointer-events: none;

  max-width: 200px;
  z-index: 15;
`;

interface ITooltipWrapperProps extends React.PropsWithChildren {
  text: string;
  position?: TPosition;
}

const TooltipWrapper: React.FC<ITooltipWrapperProps> = (props) => {
  const [visible, setVisible] = React.useState(false);
  const onMouseEnter = () => {
    setVisible(true);
  };

  const onMouseLeave = () => {
    setVisible(false);
  };

  const [offsets, setOffsets] = React.useState<[number, number, number, number]>([0, 0, 0, 0]);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (wrapperRef.current) {
      const bounds = wrapperRef.current.getBoundingClientRect();
      const [cx, cy] = [(bounds.left + bounds.right) / 2, (bounds.top + bounds.bottom) / 2];
      let offsetLeft = cx - bounds.left;
      let offsetTop = cy - bounds.top;

      /*
      if (!props.position) {
        if (window.innerWidth - cx < 50) {
          offsetX = -5;
          setPosition('left');
        } else if (cy < 50) {
          setPosition('bottom');
        }
      }
      */

      setOffsets([offsetLeft, offsetTop, 0, 0]);
    }
  }, [visible, props.position]);

  return (
    <Wrapper onMouseOver={onMouseEnter} onFocus={onMouseEnter} onMouseOut={onMouseLeave} onBlur={onMouseLeave} ref={wrapperRef}>
      {visible && <TooltipBubble offsets={offsets}>{props.text}</TooltipBubble>}
      {props.children}
    </Wrapper>
  );
};

export default TooltipWrapper;
