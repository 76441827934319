import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Decks from '../../api/decks';
import TTSAPI from '../../api/tts';
import useAuthState from '../../state/auth';
import { ITag } from '../../types/tag';
import { TTrainingResponseDifficulty } from '../../types/training';
import useOnline from '../../utils/online';
import Stateless from './index.stateless';

export interface ITrainingCard {
  cardId: string;
  front: string;
  frontSub: string;
  back: string;
  backSub: string;
  literal: string;
  literalOnFront: boolean;
  notes?: string;
  streak: number;
  easiness: number;
  hasTTS: boolean;
}

const Training: React.FC = () => {
  const params = useParams<{ id: string }>();
  const auth = useAuthState();
  const navigate = useNavigate();

  const [trainingCard, setTrainingCard] = React.useState<ITrainingCard | undefined>(undefined);
  const [flipped, setFlipped] = React.useState(false);

  const [loading, setLoading] = React.useState(true);

  const [showKeybinds, setShowKeybinds] = React.useState(true);

  const [activeTag, setActiveTag] = React.useState(localStorage.getItem('tag') || '');

  const fetchingNewCard = React.useRef(false);

  const online = useOnline();

  const getNewCard = async () => {
    if (!fetchingNewCard.current) {
      fetchingNewCard.current = true;
      setLoading(true);
      setFlipped(false);
      const data: ITrainingCard = await Decks.GetTrainingCard(auth.User?.token || '', params.id || '', { tag: activeTag });
      setTrainingCard(data);
      setLoading(false);
      fetchingNewCard.current = false;
    }
  };

  const onSubmit = async (difficulty: TTrainingResponseDifficulty) => {
    setLoading(true);
    plausible('Training/Submit', { props: { difficulty: difficulty.toString() } });
    await Decks.FinishTrainingCard(auth.User?.token || '', params.id || '', trainingCard?.cardId || '', difficulty, activeTag);
    await getNewCard();
    setLoading(false);
  };

  const getCurrentTTS = async () => {
    const blob = await TTSAPI.GetCard(auth.User?.token || '', trainingCard?.cardId || '');
    return blob;
  };

  React.useEffect(() => {
    getNewCard();
  }, [params.id, activeTag]);

  React.useEffect(() => {
    const onKeyPress = (event: KeyboardEvent) => {
      const key = event.code;

      if (!flipped && (key === 'Space' || key === 'Enter' || key === 'KeyF' || key === 'ArrowUp')) {
        plausible('Training/Keyboard Shortcut', { props: { key: key } });
        event.preventDefault();
        setFlipped(true);
      }
      if (key === 'Digit1' && flipped) {
        plausible('Training/Keyboard Shortcut', { props: { key: key } });
        event.preventDefault();
        onSubmit(0);
      }
      if ((key === 'Digit2' || key === 'Space' || key === 'Enter') && flipped) {
        plausible('Training/Keyboard Shortcut', { props: { key: key } });
        event.preventDefault();
        onSubmit(5);
      }
      if (key === 'F1') {
        event.preventDefault();
        setShowKeybinds((cur) => !cur);
      }
    };

    document.addEventListener('keydown', onKeyPress);

    return () => {
      document.removeEventListener('keydown', onKeyPress);
    };
  }, [flipped]);

  const onTagSelected = (tag: string) => {
    if (tag) {
      localStorage.setItem('tag', tag);
      setActiveTag(tag);
    } else {
      localStorage.removeItem('tag');
      setActiveTag('');
    }
  };

  const [allTags, setAllTags] = React.useState<ITag[]>([]);
  React.useEffect(() => {
    Decks.GetTags(auth.User?.token || '')
      .then((tags) => {
        setAllTags(tags.tags || []);
      })
      .catch((err) => {
        console.error(err);
        setAllTags([]);
      });
  }, []);

  const back = () => navigate(`/dashboard/decks/${params.id}`);
  const flip = () => setFlipped(true);

  return (
    <Stateless
      activeTag={activeTag}
      trainingCard={trainingCard}
      flipped={flipped}
      showKeybinds={showKeybinds}
      loading={loading}
      allTags={allTags}
      back={back}
      flip={flip}
      getNewCard={getNewCard}
      onSubmit={onSubmit}
      getCurrentTTS={getCurrentTTS}
      onTagSelected={onTagSelected}
      online={online}
    />
  );
};

export default Training;
