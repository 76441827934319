import * as React from 'react';
import styled from 'styled-components';

const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  height: 14px;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 10px;
  background: ${(props) => props.theme.colors.backgroundInput};
`;

interface IProgressBarFillingProps {
  percent: number;
}

const ProgressBarFilling = styled.div<IProgressBarFillingProps>`
  width: ${(props) => props.percent * 100}%;
  background: ${(props) => props.theme.colors.accent};
  padding: 2px;
  box-sizing: border-box;
  height: 8px;
  margin: 0 4px;
  border-radius: 2px;
`;

interface IProgressBarProps {
  percent: number;
}

const ProgressBar: React.FC<IProgressBarProps> = (props) => {
  return (
    <ProgressBarContainer>
      <ProgressBarFilling percent={props.percent} />
    </ProgressBarContainer>
  );
};

export default ProgressBar;
