import styled from 'styled-components';

const FormError = styled.div`
  display: block;
  margin-top: 10px;
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.error};
`;

export default FormError;
