import create from 'zustand';

export interface IUser {
  name: string;
  email: string;
  token: string;
  country: string;
  postalcode: string;
  stripeSubscriptionStatus: string;
}

interface IUserState {
  User: IUser | undefined;
  SetUser: (user: IUser | undefined) => void;
}

const useAuthState = create<IUserState>((set) => ({
  User: undefined,
  SetUser: (user) => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
    set((state) => ({ ...state, User: user }));
  },
}));

export default useAuthState;
