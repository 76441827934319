import { PATTERN_EMAIL } from './patterns';

export const NameValidators = {
  required: { value: true, message: 'Name is required.' },
};

export const CountryValidators = {
  required: { value: true, message: 'Country is required.' },
};

export const EmailValidators = {
  required: { value: true, message: 'Email is required.' },
  pattern: { value: PATTERN_EMAIL, message: 'Please enter a valid email.' },
};

export const PasswordValidators = {
  required: { value: true, message: 'Password is required.' },
  minLength: { value: 8, message: 'Password must be at least 8 characters.' },
};
