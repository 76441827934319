import * as React from 'react';

const useOutsideAlerter = (ref: React.RefObject<HTMLElement>, callback: () => void, deps: any[]) => {
  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, ...deps]);
};

export default useOutsideAlerter;
