import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import FormError from '../FormError';
import Header from '../Header';
import IconButton from '../IconButton';
import InputRow from '../InputRow';
import Label from '../Label';
import Loader from '../Loader';
import Modal from '../Modal';
import SpaceBetween from '../SpaceBetween';
import { TextArea } from '../TextInput';

const FeedbackNote = styled.div`
  margin-bottom: 50px;
  color: ${(props) => props.theme.colors.foregroundDim};
`;

interface IFeedbackData {
  message: string;
}

interface IFeedbackModalStatelessProps {
  onSubmit: (message: string) => Promise<void>;
}

const possibilities = ['I think Pomelo should have...', 'I found a bug in Pomelo where...', 'It would be nice if Pomelo had...'];

const FeedbackModalStateless: React.FC<IFeedbackModalStatelessProps> = (props) => {
  const form = useForm<IFeedbackData>();
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState('');

  const send = form.handleSubmit(async (data) => {
    setSubmitting(true);
    try {
      await props.onSubmit(data.message);
    } catch (err: any) {
      if (err.error) {
        setError(err.error);
      } else {
        // Feedback is transient so let's just show a toast
        setError('error');
      }
    }
    setSubmitting(false);
  });

  const feedbackPrompt = React.useRef<string>(possibilities[Math.floor(Math.random() * possibilities.length)]);

  return (
    <Modal onClickOutside={() => props.onSubmit('')}>
      <form onSubmit={send}>
        <Header size="md">Feedback</Header>
        <FeedbackNote>Please include as much context as possible. Thanks for your help!</FeedbackNote>
        <InputRow>
          <Label>Message</Label>
          <TextArea
            block
            placeholder={feedbackPrompt.current}
            {...form.register('message', { required: { value: true, message: 'Message is required.' } })}
          />
          {form.formState.errors?.message && <FormError>{form.formState.errors?.message.message}</FormError>}
          {error && <FormError>There was an error sending your feedback. Please try again later.</FormError>}
        </InputRow>
        <SpaceBetween>
          <div />
          <div>{submitting ? <Loader /> : <IconButton icon={faPaperPlane} />}</div>
        </SpaceBetween>
      </form>
    </Modal>
  );
};

export default FeedbackModalStateless;
