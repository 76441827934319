import { faCheck, faLanguage, faTrash, faVolumeDown } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import FormError from '../FormError';
import IconButton from '../IconButton';
import InputMultiRow from '../InputMultiRow';
import Label from '../Label';
import { SmallLoader } from '../Loader';
import ProgressBar from '../ProgressBar';
import SpaceBetween from '../SpaceBetween';
import TagCloud from '../TagCloud';
import TextInput, { TextArea, TextInputButtonContainer, TextInputWithButton } from '../TextInput';
import TooltipWrapper from '../TooltipWrapper';
import { ICard } from '../../state/decks';
import { ICardForm, ICardFormSubmission, LiteralValidators, NotesValidators, TranslationValidators } from '../../pages/DeckDetails/models';
import { TTS } from '../../utils/tts';

interface ICardFormProps {
  clearOnSubmit?: boolean;
  hasPronunciation?: boolean;
  defaultValue?: ICard;
  allowDeletion?: boolean;
  defaultLiteral: string;
  defaultPronunciation: string;
  romanizationMethod: string;
  hasTTS: boolean;
  onSubmit: (cardData: ICardFormSubmission) => void;
  onDelete?: () => void;
  onAudioClicked: (literal: string) => Promise<Blob>;
  onRomanizeClicked: (literal: string) => Promise<string>;
  onTranslateClicked: (translation: string) => Promise<string>;
}

const CardForm: React.FC<ICardFormProps> = (props) => {
  const form = useForm<ICardForm>({
    defaultValues: props.defaultValue
      ? {
          literal: props.defaultValue.literal,
          pronunciation: props.defaultValue.pronunciation,
          translation: props.defaultValue.translation,
          notes: props.defaultValue.notes,
        }
      : {},
  });

  const [tags, setTags] = React.useState(props.defaultValue?.tags || []);

  const submit = form.handleSubmit((data) => {
    let t = tags;
    if (props.clearOnSubmit) {
      form.setFocus('literal');
      form.reset();
      setTags([]);
    }

    props.onSubmit({ ...data, tags: t });
  });

  const onDeletionClicked = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      props.onDelete!();
    },
    [props.onDelete]
  );

  const literal = form.watch('literal');
  const translation = form.watch('translation');
  const [playAudioLoading, setPlayAudioLoading] = React.useState(false);
  const playAudio = async () => {
    setPlayAudioLoading(true);
    const blob = await props.onAudioClicked(literal);
    TTS.Play(blob);
    setPlayAudioLoading(false);
  };

  const [translateLoading, setTranslateLoading] = React.useState(false);
  const translate = async () => {
    setTranslateLoading(true);
    const text = await props.onTranslateClicked(translation);
    form.setValue('literal', text);
    form.trigger('literal');
    setTranslateLoading(false);
  };

  const [romanizeLoading, setRomanizeLoading] = React.useState(false);
  const romanize = async () => {
    setRomanizeLoading(true);
    const text = await props.onRomanizeClicked(literal);
    form.setValue('pronunciation', text);
    form.trigger('pronunciation');
    setRomanizeLoading(false);
  };

  const [tagValue, setTagValue] = React.useState('');

  const addTag = () => {
    const value = tagValue;
    setTagValue('');

    if (value) {
      setTags((tags) => [...tags, { id: (Math.random() * 1000000).toString(), value }]);
    }
  };

  const onAddTagKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      addTag();
    }
  };

  const onTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagValue((event.target as HTMLInputElement).value);
  };

  const removeTag = (index: number) => {
    const newArr = [...tags];
    newArr.splice(index, 1);
    setTags(newArr);
  };

  const mastery = Math.min(Math.max((props.defaultValue?.streak || 0) / 25, 0), 1);

  return (
    <form onSubmit={submit}>
      <InputMultiRow>
        <div>
          <Label>Literal</Label>
          <TextInputWithButton>
            <TextInput {...form.register('literal', LiteralValidators)} placeholder={props.defaultLiteral} block />
            {props.hasTTS && (
              <TextInputButtonContainer>
                {playAudioLoading ? (
                  <SmallLoader />
                ) : (
                  <TooltipWrapper text="Play audio">
                    <IconButton icon={faVolumeDown} borderless onClick={playAudio} type="button" disabled={!(literal && literal.trim())} />
                  </TooltipWrapper>
                )}
              </TextInputButtonContainer>
            )}
          </TextInputWithButton>
          {form.formState.errors?.literal && <FormError>{form.formState.errors?.literal?.message}</FormError>}
        </div>
        {props.hasPronunciation && (
          <div>
            <Label>Pronunciation</Label>
            <TextInputWithButton>
              <TextInput {...form.register('pronunciation')} placeholder={props.defaultPronunciation} block />
              {props.romanizationMethod && (
                <TextInputButtonContainer>
                  {romanizeLoading ? (
                    <SmallLoader />
                  ) : (
                    <TooltipWrapper text="Romanize">
                      <IconButton icon={faLanguage} borderless onClick={romanize} type="button" disabled={!(literal && literal.trim())} />
                    </TooltipWrapper>
                  )}
                </TextInputButtonContainer>
              )}
            </TextInputWithButton>
          </div>
        )}
        <div>
          <Label>Translation</Label>
          <TextInputWithButton>
            <TextInput {...form.register('translation', TranslationValidators)} placeholder="hello" block />
            <TextInputButtonContainer>
              {translateLoading ? (
                <SmallLoader />
              ) : (
                <TooltipWrapper text="Translate to target language">
                  <IconButton
                    icon={faLanguage}
                    borderless
                    onClick={translate}
                    type="button"
                    disabled={!(translation && translation.trim())}
                  />
                </TooltipWrapper>
              )}
            </TextInputButtonContainer>
          </TextInputWithButton>
          {form.formState.errors?.translation && <FormError>{form.formState.errors?.translation.message}</FormError>}
        </div>
        <div>
          <Label>Notes</Label>
          <TextArea {...form.register('notes', NotesValidators)} placeholder="Mnemonic, detail, info, etc." block maxWidth={500} />
          {form.formState.errors?.notes && <FormError>{form.formState.errors?.notes.message}</FormError>}
        </div>
        {props.defaultValue !== undefined && (
          <div>
            <Label>Mastery</Label>
            <ProgressBar percent={mastery} />
          </div>
        )}
        <div>
          <Label>Tags</Label>
          {tags.length > 0 && <TagCloud tags={tags} onTagClicked={removeTag} />}
          <TextInputWithButton>
            <TextInput placeholder="New tag..." block onKeyDown={onAddTagKeyDown} onChange={onTagChange} value={tagValue} />
            <TextInputButtonContainer>
              <TooltipWrapper text="Add">
                <IconButton icon={faCheck} onClick={addTag} type="button" borderless disabled={!tagValue} />
              </TooltipWrapper>
            </TextInputButtonContainer>
          </TextInputWithButton>
        </div>
      </InputMultiRow>
      <SpaceBetween>
        <IconButton icon={faCheck} big />
        {props.allowDeletion && <IconButton type="button" danger onClick={onDeletionClicked} icon={faTrash} />}
      </SpaceBetween>
    </form>
  );
};

export default CardForm;
