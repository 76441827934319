import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Auth from '../../api/auth';
import useAuthState from '../../state/auth';
import Stateless from './index.stateless';
import { ILoginFormData } from './models';

const Login: React.FC = () => {
  const authState = useAuthState();
  const navigate = useNavigate();

  React.useEffect(() => {
    plausible('Login/View');
  }, []);

  const onSubmit = async (data: ILoginFormData) => {
    plausible('Login/Finalize');
    const userData = await Auth.Login(data);
    authState.SetUser(userData);
    localStorage.removeItem('mock');
    navigate(`/dashboard`);
  };

  const navigateRegister = () => {
    navigate('/register');
  };

  return <Stateless onSubmit={onSubmit} navigateRegister={navigateRegister} />;
};

export default Login;
